import { useSelector } from "react-redux";
import { IBetaUser } from "../../types/betaUser";
import { REQUEST_BETA_USERS, SET_BETA_USERS } from "./betaUserActionType";

interface IState {
  busy: boolean;
  message: string;
  betaUser: IBetaUser[];
}

const initialState: IState = {
  busy: false,
  message: "",
  betaUser: [],
};

const betaUser = (state = initialState, action: Record<string, any>) => {
  switch (action.type) {
    case REQUEST_BETA_USERS:
      return {
        ...state,
        busy: true,
        message: "",
      };

    case SET_BETA_USERS:
      return {
        ...state,
        busy: false,
        message: "",
        betaUser: action.payload,
      };

    default:
      return state;
  }
};
export default betaUser;
export function useBetaUser() {
  return useSelector((state: Record<string, any>) => state.betaUsers);
}
