import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { get } from "lodash";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { REQUEST_DELETE_ACCOUNT } from "../../../../store/account/accountActionType";
import { useAccounts } from "../../../../store/account/reducer";
import { REQUEST_DELETE_USER } from "../../../../store/user/userActionType";
import { IAccount } from "../../../../types/account";
interface Props {
  user: any;
  isOpen: any;
  onClose: any;
}
const UserDeleteModal: React.FC<Props> = ({ isOpen, onClose, user }) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const { busy, message } = useAccounts();

  return (
    <Modal
      id="deletAcMenu"
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete User</ModalHeader>
        <ModalCloseButton disabled={busy} />
        <ModalBody>
          <Text color="red">Are you sure to remove this User ?</Text>
          <Text> {get(user, "email")}</Text>
          {message && (
            <Alert status="error" zIndex={100}>
              <AlertIcon />
              <AlertTitle mr={2}>{message}</AlertTitle>
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" mr={3} onClick={onClose} disabled={busy}>
            Close
          </Button>
          <Button
            colorScheme="red"
            variant={"outline"}
            mr={3}
            onClick={() => {
              if (get(user, "user._id")) {
                dispatch({
                  type: REQUEST_DELETE_USER,
                  payload: { accountId: get(user, "_id") },
                  onSuccess: () => {
                    navigate("../");
                  },
                });
              }
            }}
            disabled={busy}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default UserDeleteModal;
