import { Button, Image, useDisclosure } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Stddata from "./Stddata";

import styles from "./LinksForm.module.css";
import * as Yup from "yup";

import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import {
  REQUEST_CREATE_MASTER_LINK,
  REQUEST_MASTER_LINKS,
  REQUEST_UPDATE_MASTER_LINK,
} from "../../../store/linkMaster/linkActionTypes";
import SwitchButton from "../../FormFields/SwitchButton/SwitchButton";
import ImageFormField from "../../FormFields/ImageFormField/ImageFormField";
import { TextFormField } from "../../FormFields/TextFormField/TextFormField";
import { useLinkCategories } from "../../../store/linkCategory/reducer";
import SelectFormField from "../../FormFields/SelectFormFields/SelectFormField";
import RadioFormFields from "../../FormFields/RadioFormField/RadioFormFields";
import { ILinks } from "../../../types/links";
import { REQUEST_LINK_CATEGORY } from "../../../store/linkCategory/linkCategoryActionType";
import { AES, enc } from "crypto-js";
import { useLinksMaster } from "../../../store/linkMaster/reducer";
import LinkDeleteConfirmation from "../../modals/LinkDeleteConfirmation";
import { MdLabel } from "react-icons/md";

const addSchema = Yup.object({
  title: Yup.string().min(2, "Too Short!").required("Required"),
  logo: Yup.string().required("A file is required"),
  // .test(
  //   "fileSize",
  //   "File too large",
  //   (value) => value && value.size <= FILE_SIZE
  // )
  // .test(
  //   "fileFormat",
  //   "Unsupported Format",
  //   (value) => value && SUPPORTED_FORMATS.includes(value.type)
  // ),
  isPro: Yup.boolean().required("Required"),
  prefix: Yup.string().min(2, "Too Short!").optional(),
  suffix: Yup.string().min(2, "Too Short!").optional(),
  androidPrefix: Yup.string().min(2, "Too Short!").optional(),
  iosPrefix: Yup.string().min(2, "Too Short!").optional(),
  extraLabel: Yup.boolean().required("Required"),
  extraImage: Yup.boolean().required("Required"),
  isDeactive: Yup.boolean().required("Required"),
  extraPlaceholder: Yup.string().min(2, "Too Short!").optional(),
  placeholder: Yup.string().min(2, "Too Short!").required("Required"),
  type: Yup.object().required("Required"),

  // key: Yup.string().optional(),
  category: Yup.array()
    .required("Required")
    .of(
      Yup.object().shape({
        value: Yup.string().required(),
      })
    )
    .min(1, "Select at least one Category"),
  forFreeUser: Yup.number().required("Required"),
  forPaidUser: Yup.number().required("Required"),
});

export const LinksForm: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { linkCategory, busy: linkCategoryBusy } = useLinkCategories();
  const { masterLinks, busy: linkBusy } = useLinksMaster();

  const [link, setLink] = useState<ILinks>();
  let navigate = useNavigate();

  const [targetLink, setTargetLink] = useState<ILinks>();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();

  const deleteLink = (linkMaster: ILinks) => {
    setTargetLink(linkMaster);
    onDeleteOpen();
  };

  useEffect(() => {
    if (!linkCategoryBusy && !(linkCategory || []).length) {
      dispatch({ type: REQUEST_LINK_CATEGORY });
    }

    if (!linkBusy && !(masterLinks || []).length) {
      dispatch({ type: REQUEST_MASTER_LINKS });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (params.id) {
      const aesToken = window.atob(params.id);
      const linkId =
        process.env.REACT_APP_AES_KEY !== undefined
          ? AES.decrypt(aesToken, process.env.REACT_APP_AES_KEY).toString(
              enc.Utf8
            )
          : aesToken.toString();
      setLink(masterLinks.find((item: ILinks) => item._id === linkId));
    }
  }, [masterLinks, link, linkCategory, params.id]);

  const typeOptions: any[] = useMemo(() => {
    return [
      { value: "text", label: "Text" },
      { value: "number", label: "Number" },
      { value: "links", label: "Contact Card" },
      { value: "Youtube Embedded", label: "Youtube Embedded" },
      { value: "email", label: "Email" },
      { value: "file", label: "File" },
    ];
  }, []);

  const categoryOption = useMemo(
    () =>
      linkCategory.map((lc: Record<string, any>) => {
        return {
          label: lc.title,
          value: lc._id,
        };
      }),
    [linkCategory]
  );

  const initialValue = useMemo(() => {
    if (link && categoryOption && categoryOption.length) {
      return {
        title: link.title,
        logo: link.logo,
        isPro: link.isPro,
        prefix: link.prefix,
        suffix: link.suffix,
        androidPrefix: link.androidPrefix,
        iosPrefix: link.iosPrefix,
        placeholder: link.placeholder,
        extraLabel: link.extraLabel,
        extraImage: link.extraImage,
        extraPlaceholder: link.extraPlaceholder,
        isDeactive: link.isDeactive,
        type: typeOptions.find((to) => to.value === link.type),
        forFreeUser: link.maxLinks.forFreeUser,
        forPaidUser: link.maxLinks.forPaidUser,
        category: (link.category || []).map(
          (value: string | Record<string, any>) => ({
            value,
            label: (
              categoryOption.find((cat: any) => value === cat.value) || {}
            ).label,
          })
        ),
      };
    }
    return {
      title: "",
      logo: "",
      isPro: false,
      prefix: "",
      suffix: "",
      androidPrefix: "",
      iosPrefix: "",
      placeholder: "",
      extraLabel: false,
      extraImage: false,
      isDeactive: false,
      extraPlaceholder: "",
      type: { value: "" },
      forFreeUser: "",
      forPaidUser: "",
      category: [],
    };
  }, [link, categoryOption, typeOptions]);

  const onSubmit = (values: Record<string, any>) => {
    let payload: Record<string, any> = {
      title: values.title,
      isPro: values.isPro,
      prefix: values.prefix,
      suffix: values.suffix,
      androidPrefix: values.androidPrefix,
      iosPrefix: values.iosPrefix,
      placeholder: values.placeholder,
      extraLabel: values.extraLabel,
      extraImage: values.extraImage,
      isDeactive: values.isDeactive,
      extraPlaceholder: values.extraPlaceholder,
      type: values.type.value,
      // key: values.key,
      logo: values.logo,
      maxLinks: {
        forFreeUser: values.forFreeUser,
        forPaidUser: values.forPaidUser,
      },
      category: values.category.map((item: any) => {
        return item.value;
      }),
    };

    if (link) {
      const data = { payload, _id: link._id };
      dispatch({
        type: REQUEST_UPDATE_MASTER_LINK,
        data,
        onSuccess: () => {
          navigate("../");
        },
      });
    } else {
      dispatch({
        type: REQUEST_CREATE_MASTER_LINK,
        payload,
        onSuccess: () => {
          navigate("../");
        },
      });
    }
  };

  if (
    !(!params.id || link) ||
    linkCategoryBusy ||
    linkBusy ||
    !(linkCategory || []).length ||
    !(masterLinks || []).length
  ) {
    return <span>Loading....</span>;
  }
  return (
    <>
      <div className={styles.mainOuterDiv}>
        <div className={styles.MainInnerDiv}>
          <div className={styles.homeBtnDiv}>
            <Link to="/">
              <Image
                className={styles.homeIcon}
                src="/Images/homebutton.png"
                alt="home button"
              ></Image>
            </Link>
          </div>
          <div className={styles.GgoBackDiv}>
            <Link to="/links">
              <p className={styles.goBackIcon}>Go Back</p>
            </Link>
          </div>
          <Formik
            initialValues={initialValue}
            validationSchema={addSchema}
            onSubmit={onSubmit}
          >
            {({ values, setFieldValue, errors, touched, submitForm }) => (
              <Form>
                <div className={styles.mainTopBtnLogoDiv}>
                <SwitchButton
                  required={false}
                  name="Hide / Show "
                  fieldName="isDeactive"
                  error={
                    touched.isDeactive
                      ? (errors.isDeactive as string)
                      : undefined
                  }
                />
                <div className={styles.appImgDiv}>
                  <ImageFormField
                    image={values["logo"]}
                    disabled={false}
                    onSuccess={({ img: image }: { img: string }) => {
                      if (image) setFieldValue("logo", image);
                    }}
                    error={touched.logo ? (errors.logo as string) : undefined}
                  />
                </div>
                </div>

                <div className={styles.appDeepDetails}>
                  <div className={styles.appDetailsInner} id="mainDivOfText">
                    <label className={styles.fieldNameLabel}>Name :</label>
                    <TextFormField
                      required={true}
                      disabled={false}
                      name=" "
                      fieldName="title"
                      type="text"
                      // className={styles.textfield}
                      error={
                        touched.title ? (errors.title as string) : undefined
                      }
                    />
                    <div className={styles.btnoption}>
                      <Stddata
                        proValue={values.isPro}
                        onChangeData={(data: any) => {
                          values.isPro = data.name === "pro" ? true : false;
                        }}
                      />
                    </div>
                    <label className={styles.fieldNameLabel}>Prefix :</label>
                    <TextFormField
                      required={true}
                      disabled={false}
                      name=" "
                      fieldName="prefix"
                      type="text"
                      error={
                        touched.prefix ? (errors.prefix as string) : undefined
                      }
                    />
                    <label className={styles.fieldNameLabel}>Suffix :</label>
                    <TextFormField
                      required={true}
                      disabled={false}
                      name=" "
                      fieldName="suffix"
                      type="text"
                      error={
                        touched.suffix ? (errors.suffix as string) : undefined
                      }
                    />
                    <label className={styles.fieldNameLabel}>
                      Android Prefix :
                    </label>
                    <TextFormField
                      required={true}
                      disabled={false}
                      name=" "
                      fieldName="androidPrefix"
                      type="text"
                      error={
                        touched.androidPrefix
                          ? (errors.androidPrefix as string)
                          : undefined
                      }
                    />
                    <label className={styles.fieldNameLabel}>
                      IOS Prefix :
                    </label>
                    <TextFormField
                      required={true}
                      disabled={false}
                      name=" "
                      fieldName="iosPrefix"
                      type="text"
                      error={
                        touched.iosPrefix
                          ? (errors.iosPrefix as string)
                          : undefined
                      }
                    />
                    <label className={styles.fieldNameLabel}>
                      Placeholder :
                    </label>
                    <TextFormField
                      required={true}
                      disabled={false}
                      name=" "
                      fieldName="placeholder"
                      type="text"
                      error={
                        touched.placeholder
                          ? (errors.placeholder as string)
                          : undefined
                      }
                    />
                    <label className={styles.fieldNameLabel}>
                      Skipped word :{" "}
                    </label>

                    <SelectFormField
                      value={values["type"]}
                      options={typeOptions}
                      required={true}
                      name={""}
                      fieldName={"type"}
                      isMulti={false}
                      placeholder={"Select a Type"}
                      error={touched.type ? (errors.type as string) : undefined}
                    />
                    <label className={styles.fieldNameLabel}>Category : </label>
                    <SelectFormField
                      value={values["category"]}
                      options={categoryOption}
                      required={true}
                      name={""}
                      fieldName={"category"}
                      isMulti={true}
                      placeholder={"Select a Category"}
                      error={
                        touched.category
                          ? (errors.category as string)
                          : undefined
                      }
                    />
                    <label className={styles.fieldNameLabel}>Free User :</label>
                    <TextFormField
                      required={true}
                      disabled={false}
                      name=" "
                      fieldName="forFreeUser"
                      type="number"
                      error={
                        touched.forFreeUser
                          ? (errors.forFreeUser as string)
                          : undefined
                      }
                    />
                    <label className={styles.fieldNameLabel}>Paid User :</label>
                    <span className={styles.remoeveborder}>
                      <TextFormField
                        required={true}
                        disabled={false}
                        name=" "
                        fieldName="forPaidUser"
                        type="number"
                        error={
                          touched.forPaidUser
                            ? (errors.forPaidUser as string)
                            : undefined
                        }
                      />
                    </span>
                    <div className={styles.radioFormFieldMainDiv}>
                      <label className={styles.fieldNameLabel}>
                        Extra Label :
                      </label>
                      <RadioFormFields
                        required={true}
                        name={""}
                        fieldName={"extraLabel"}
                        error={
                          touched.extraLabel
                            ? (errors.extraLabel as string)
                            : undefined
                        }
                      />
                    </div>

                    <div className={styles.radioFormFieldMainDiv}>
                      <label className={styles.fieldNameLabel}>
                        Extra Image :
                      </label>
                      <RadioFormFields
                        required={true}
                        name={""}
                        fieldName={"extraImage"}
                        error={
                          touched.extraImage
                            ? (errors.extraImage as string)
                            : undefined
                        }
                      />
                    </div>

                    <label className={styles.fieldNameLabel}>
                      Extra Placeholder :{" "}
                    </label>
                    <TextFormField
                      required={true}
                      disabled={false}
                      name={""}
                      fieldName={"extraPlaceholder"}
                      error={
                        touched.extraPlaceholder
                          ? (errors.extraPlaceholder as string)
                          : undefined
                      }
                      type="text"
                    />
                    
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          <div className={styles.btnLastOption}>
                      {link ? (
                        <div className={styles.saveRemoveBtnMainDiv}>
                          <Button
                            className={` ${styles.removeLink} ${styles.copyBtnStyle} `}
                            onClick={() => {
                              deleteLink(link);
                            }}
                          >
                            Remove Link
                          </Button>
                        </div>
                      ) : null}
                      <div className={styles.saveRemoveBtnMainDiv}>
                        <Button
                          className={` ${styles.save} ${styles.copyBtnStyle} `}
                          type={"submit"}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
        </div>
      </div>
      {isDeleteOpen && targetLink && (
        <LinkDeleteConfirmation
          link={targetLink}
          isOpen={isDeleteOpen}
          onClose={onDeleteClose}
        />
      )}
    </>
  );
};
