import { get, sortBy } from "lodash";
import moment from "moment";
import { IAccount } from "../../types/account";

export const sortAccount = (accounts: IAccount[], type: string): IAccount[] => {
  if (type === "oldest") {
    return sortAccountByAsceDate(accounts);
  } else if (type === "newest") {
    return sortAccountByDescDate(accounts);
  } else if (type === "mostView") {
    return sortAccountByArrayByFieldForNumbers(accounts, "views").reverse();
  } else if (type === "leastView") {
    return sortAccountByArrayByFieldForNumbers(accounts, "views");
  } else if (type === "AtoZ") {
    return sortAccountByArrayByFieldForString(accounts, "firstName");
  } else if (type === "ZtoA") {
    return sortAccountByArrayByFieldForString(accounts, "firstName").reverse();
  } else if (type === "Suspended User") {
    return sortAccountBySuspend(accounts).reverse();
  } else {
    return accounts;
  }
};

const sortAccountByAsceDate = (originalArray: IAccount[]) => {
  const dateNotAvailable: IAccount[] = [];
  const dateAvailable: IAccount[] = [];

  originalArray.forEach((item: IAccount) => {
    if (!item.createdAt) {
      dateNotAvailable.push(item);
    } else {
      dateAvailable.push(item);
    }
  });
  const array: IAccount[] = sortBy(dateAvailable, [
    function (dateAvailable) {
      return dateAvailable.createdAt;
    },
  ]);

  dateNotAvailable.forEach(function (item) {
    array.push(item);
  });
  return array;
};

const sortAccountByDescDate = (originalArray: IAccount[]) => {
  const dateNotAvailable: IAccount[] = [];
  const dateAvailable: IAccount[] = [];

  originalArray.forEach((item: IAccount) => {
    if (!item.createdAt) {
      dateNotAvailable.push(item);
    } else {
      dateAvailable.push(item);
    }
  });
  const array: IAccount[] = sortBy(dateAvailable, [
    function (dateAvailable) {
      return dateAvailable.createdAt;
    },
  ]).reverse();

  dateNotAvailable.forEach(function (item) {
    array.push(item);
  });
  return array;
};

const sortAccountByArrayByFieldForNumbers = (
  originalArray: IAccount[],
  fieldName: string
) => {
  const array: IAccount[] = sortBy(originalArray, [fieldName]);

  return array;
};

const sortAccountByArrayByFieldForString = (
  originalArray: Record<string, any>[],
  fieldName: string
) => {
  const array: Record<string, any>[] = sortBy(originalArray, [
    function (item) {
      return item[fieldName]?.toLowerCase();
    },
  ]);

  return array as IAccount[];
};

const sortAccountBySuspend = (
  originalArray: IAccount[],
) => {
  const array: IAccount[] = originalArray.filter((item: IAccount) => moment(get(item, "user.suspend.suspendTill")).diff(
    moment(new Date())) > 0)

  return array
}
