import React, { useMemo, useState } from "react";
import UserBtn from './UserBtn'
import styles from "./UserTable.module.css"

interface Props {
  onChangeData?: (data: any) => void
}

const UserType: React.FC<Props> = ({ onChangeData }) => {

  const [activeButton, setActiveButton] = useState(0)

  const button_Data = useMemo(() => {
    return [
      {
        name: "all",
        value: "ALL"
      },
      {
        name: "free",
        value: "FREE"
      },
      {
        name: "isPro",
        value: "PRO"
      },
     
    ]
  }, []);


  return (
    <div className={styles.btnDivFor}>
      {button_Data.map((item: any, index: number) => (
        <div key={item.value} >
          <UserBtn
            id={index}
            value={item.value}
            setActiveButton={(id: any) => {
              
              setActiveButton(id)
              onChangeData && onChangeData(button_Data[id])
            }
            }
            active={activeButton === index ? true : false}
          />

        </div>
      ))}
    </div>
  );
}

export default UserType;
