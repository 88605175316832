import styles from "./UnSuspend.module.css";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import { get } from "lodash";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useUserActions } from "../../../../store/userActions/reducer";
import { REQUEST_UNSUSPEND_USER } from "../../../../store/userActions/userActionsActionType";
import { IAccount } from "../../../../types/account";

interface Props {
  isOpen: any;
  onClose: any;
  accountData: any;
}
const UnSuspend: React.FC<Props> = ({ isOpen, onClose, accountData }) => {
  const dispatch = useDispatch();
  const { message, busy } = useUserActions();
  const toast = useToast();

  useEffect(() => {
    if (message) {
      toast({
        title: "Please Try again",
        description: message,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    }
  }, [toast, message]);

  const onUnsuspendUser = () => {
    const data = {
      _id: get(accountData, "user._id"),
      suspend: {
        suspendTill: moment().utc().toISOString(true),
        suspendMessage: "",
      },
    };
    dispatch({
      type: REQUEST_UNSUSPEND_USER,
      data,
      onSuccess: () => {
        onClose();
      },
    });
  };

  return (
    <>
      <div className={styles.mainOuterDiv}>
        <div className={styles.MainInnerDiv}>
          <Modal isOpen={isOpen} onClose={onClose} id={"unsuspend"}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader className={styles.header}>
                Are You Sure You Want To Unsuspend This User?
              </ModalHeader>

              <ModalBody className={styles.header}>
                {busy ? (
                  <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="lg"
                    mt={5}
                  />
                ) : (
                  <div className={styles.choiceOptionDiv}>
                    <Button
                      className={styles.choiceOption}
                      style={{ backgroundColor: "rgba(58, 132, 118, 1)" }}
                      onClick={onUnsuspendUser}
                    >
                      Yes
                    </Button>
                    <Button
                      onClick={onClose}
                      style={{ backgroundColor: "rgba(255, 0, 0, 1)" }}
                      className={styles.choiceOption}
                    >
                      No
                    </Button>
                  </div>
                )}
              </ModalBody>
            </ModalContent>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default UnSuspend;
