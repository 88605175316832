import { Button, Center, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import React, { useMemo } from 'react'
import { TextFormField } from '../../FormFields/TextFormField/TextFormField';
import * as Yup from "yup";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { REQUEST_CREATE_LINK_CATEGORY, REQUEST_UPDATE_LINK_CATEGORY } from '../../../store/linkCategory/linkCategoryActionType';

interface Props {
    linkCategory?: Record<string, any>;
    isOpen: any;
    onClose: any;
}

const addSchema = Yup.object({
    title: Yup.string().min(2, "Too Short!").required("Required"),
})

const AddLinksCategory: React.FC<Props> = ({
    linkCategory,
    isOpen,
    onClose,
}) => {
    const initialValue = useMemo(() => {
        if (linkCategory) {
            return {
                title: linkCategory.title,
            };
        }
        return {
            title: "",
        };
    }, []);

    const dispatch = useDispatch();
    let navigate = useNavigate();



    const onSubmit = (values: Record<string, any>) => {
        let payload: Record<string, any> = {
            title: values.title,

        };


        if (linkCategory) {
            const data = { payload, _id: linkCategory._id };
            dispatch({
                type: REQUEST_UPDATE_LINK_CATEGORY,
                data,
                onSuccess: () => {
                    onClose()
                },
            });
        } else {
            dispatch({
                type: REQUEST_CREATE_LINK_CATEGORY,
                payload,
                onSuccess: () => {
                    onClose()
                },
            });
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} id="addCatBox">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Add Categorey</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Formik
                        initialValues={initialValue}
                        validationSchema={addSchema}
                        onSubmit={onSubmit}
                    >
                        {({ values, setFieldValue, errors, touched, submitForm }) => (
                            <Form>
                                {/* <FormControl>
                                    <FormLabel>Category Name</FormLabel>
                                    <Input placeholder='Enter category name' />
                                </FormControl> */}
                                
                                <TextFormField
                                    required={true}
                                    disabled={false}
                                    name="Title : "
                                    fieldName="title"
                                    type="text"
                                    error={
                                        touched.title ? (errors.title as string) : undefined
                                    }
                                />
                                <Center>

                                    <Button
                                        // className={` ${styles.save} ${styles.copyBtnStyle} `}
                                        type={"submit"}
                                    >
                                        Save
                                    </Button>
                                </Center>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>

                {/* <ModalFooter> */}
                {/* <Button colorScheme='blue' mr={3} onClick={onClose}>
                        Close
                    </Button>
                    <Button variant='ghost'>Secondary Action</Button> */}
                {/* </ModalFooter> */}
            </ModalContent>
        </Modal>
    )
}

export default AddLinksCategory