import { useSelector } from "react-redux";
import { IFeedBackType } from "../../types/IFeedBackType";
import {
  REQUEST_FEEDBACK_MESSAGE_TYPE,
  SET_FEEDBACK_MESSAGE_TYPE,
} from "./feedbackMessageTypeActionTypes";

interface IState {
  busy: boolean;
  message: string;
  feedbackMessageType: IFeedBackType[];
}

const initialState: IState = {
  busy: false,
  message: "",
  feedbackMessageType: [],
};

const feedbackMessageType = (
  state: IState = initialState,
  action: Record<string, any>
) => {
  switch (action.type) {
    case REQUEST_FEEDBACK_MESSAGE_TYPE:
      return {
        ...state,
        busy: true,
        message: "",
      };

    case SET_FEEDBACK_MESSAGE_TYPE:
      return {
        ...state,
        busy: false,
        message: "",
        feedbackMessageType: action.payload,
      };

    default:
      return state;
  }
};
export default feedbackMessageType;

export function useFeedbackMessageType() {
  return useSelector((state: Record<string, any>) => state.feedbackMessageType);
}
