import axios from "axios";
import { get } from "lodash";
import { all, put, takeLatest, call } from "redux-saga/effects";
import {
  REQUEST_DELETE_ACCOUNT,
  ERROR_UPDATE_ACCOUNT,
  REQUEST_ACCOUNTS,
  REQUEST_UPDATE_ACCOUNT,
  SET_ACCOUNTS,
  ERROR_DELETE_ACCOUNT,
  SUCCESS_UPDATE_ACCOUNT,
  REQUEST_ACCOUNT_VERIFY,
  SUCCESS_ACCOUNT_VERIFY,
  ERROR_ACCOUNT_VERIFY,
} from "./accountActionType";

function* requestAccounts(action: Record<string, any>): any {
  try {
    const result: any = yield call(getAccounts, action.page);
    yield put({ type: SET_ACCOUNTS, payload: result.data });
  } catch (err) {
    console.error(`Error fetching switched account`, action);
  }
}

function* requestDeleteUser(action: Record<string, any>): any {
  try {
    yield call(deleteAccount, get(action, "payload.accountId", ""));
    yield put({
      type: ERROR_DELETE_ACCOUNT,
      payload: get(action, "payload.accountId", ""),
    });
    action && action.onSuccess && action.onSuccess();
  } catch (error: any) {
    let message =
      "Something went wrong, please try again after some time or Refresh the Page.";
    if (get(error, "response.status") === 500) {
      message = "Something happened wrong try again after sometime.";
    } else if (get(error, "response.status") === 422) {
      message = "please provide valid contain";
    } else if (get(error, "response.status") === 415) {
      message = error.response.data.message;
    }
    yield put({ type: SET_ACCOUNTS, payload: message });
  }
}

function* requestUpdateAccount(action: Record<string, any>): any {
  try {
    const result = yield call(
      updateAccount,
      action.data.payload,
      action.data._id
    );
    yield put({
      type: SUCCESS_UPDATE_ACCOUNT,
      payload: result.data,
    });

    action && action.onSuccess && action.onSuccess();
  } catch (error: any) {
    let message =
      "Something went wrong, please try again after some time or Refresh the Page.";
    if (get(error, "response.status") === 500) {
      message = "Something happened wrong try again after sometime.";
    } else if (get(error, "response.status") === 422) {
      message = "please provide valid contain";
    } else if (get(error, "response.status") === 415) {
      message = error.response.data.message;
    }
    action && action.onError && action.onError(message);

    yield put({ type: ERROR_UPDATE_ACCOUNT, payload: message });
  }
}

function* requestVerifyAccount(action: Record<string, any>): any {
  try {
    yield call(verifyAccount, get(action, "payload.accountId", ""));

    yield put({
      type: SUCCESS_ACCOUNT_VERIFY,
      payload: action.payload,
    });
    action && action.onSuccess && action.onSuccess();
  } catch (error: any) {
    let message =
      "Something went wrong, please try again after some time or Refresh the Page.";
    if (get(error, "response.status") === 500) {
      message = "Something happened wrong try again after sometime.";
    } else if (get(error, "response.status") === 422) {
      message = "please provide valid contain";
    } else if (get(error, "response.status") === 415) {
      message = error.response.data.message;
    }
    yield put({ type: ERROR_ACCOUNT_VERIFY, payload: message });
  }
}

export function getAccounts(page: number) {
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_BASE_URL}/account?page=${page}`,
    withCredentials: true,
  });
}

export function deleteAccount(_id: string) {
  return axios({
    method: "delete",
    url: `${process.env.REACT_APP_BASE_URL}/account/${_id}`,
    withCredentials: true,
  });
}

export function updateAccount(payload: Record<string, any>, _id: string) {
  return axios({
    method: "patch",
    url: `${process.env.REACT_APP_BASE_URL}/account/${_id}`,
    data: payload,
    withCredentials: true,
  });
}

export function verifyAccount(_id: string) {
  return axios({
    method: "put",
    url: `${process.env.REACT_APP_BASE_URL}/account/${_id}/verify`,
    withCredentials: true,
  });
}

const accountSaga = function* () {
  yield all([
    takeLatest(REQUEST_ACCOUNTS, requestAccounts),
    takeLatest(REQUEST_DELETE_ACCOUNT, requestDeleteUser),
    takeLatest(REQUEST_UPDATE_ACCOUNT, requestUpdateAccount),
    takeLatest(REQUEST_ACCOUNT_VERIFY, requestVerifyAccount),
  ]);
};

export default accountSaga;
