export const REQUEST_ACCOUNTS = "REQUEST_ACCOUNTS";
export const SET_ACCOUNTS = "SET_ACCOUNTS";
export const ERROR_GET_ACCOUNTS = "ERROR_GET_ACCOUNTS";

export const REQUEST_UPDATE_ACCOUNT = "REQUEST_UPDATE_ACCOUNT";
export const SUCCESS_UPDATE_ACCOUNT = "SUCCESS_UPDATE_ACCOUNT";
export const ERROR_UPDATE_ACCOUNT = "ERROR_UPDATE_ACCOUNT";

export const SUCCESS_USER_SUSPEND_ACCOUNT_UPDATE =
  "SUCCESS_USER_SUSPEND_ACCOUNT_UPDATE";

export const REQUEST_DELETE_ACCOUNT = "REQUEST_DELETE_ACCOUNT";
export const SET_DELETE_ACCOUNT = "SET_DELETE_ACCOUNT";
export const ERROR_DELETE_ACCOUNT = "ERROR_DELETE_ACCOUNT";

export const REQUEST_ACCOUNT_VERIFY = "REQUEST_ACCOUNT_VERIFY";
export const SUCCESS_ACCOUNT_VERIFY = "SUCCESS_ACCOUNT_VERIFY";
export const ERROR_ACCOUNT_VERIFY = "ERROR_ACCOUNT_VERIFY";

export const PRO_USER_SUCCESS_ACCOUNT = "PRO_USER_SUCCESS_ACCOUNT";