import { IUser } from './../../types/user';
import { get, sortBy } from "lodash";
import moment from "moment";
// import { IAccount } from "../../types/account";

export const sortUser = (users: IUser[], type: string): IUser[] => {
  if (type === "oldest") {
    return sortUserByAsceDate(users);
  } else if (type === "newest") {
    return sortUserByAsceDate(users);
  } else if (type === "mostView") {
    return sortUserByArrayByFieldForNumbers(users, "views").reverse();
  } else if (type === "leastView") {
    return sortUserByArrayByFieldForNumbers(users, "views");
  } else if (type === "AtoZ") {
    return sortUserByArrayByFieldForString(users, "firstName");
  } else if (type === "ZtoA") {
    return sortUserByArrayByFieldForString(users, "firstName").reverse();
  } else if (type === "Suspended User") {
    return sortUserBySuspend(users).reverse();
  } else {
    return users;
  }
};

const sortUserByAsceDate = (originalArray: IUser[]) => {
  const dateNotAvailable: IUser[] = [];
  const dateAvailable: IUser[] = [];

  originalArray.forEach((item: IUser) => {
    if (!item.createdAt) {
      dateNotAvailable.push(item);
    } else {
      dateAvailable.push(item);
    }
  });
  const array: IUser[] = sortBy(dateAvailable, [
    function (dateAvailable) {
      return dateAvailable.createdAt;
    },
  ]);

  dateNotAvailable.forEach(function (item) {
    array.push(item);
  });
  return array;
};

const sortUserByDescDate = (originalArray: IUser[]) => {
  const dateNotAvailable: IUser[] = [];
  const dateAvailable: IUser[] = [];

  originalArray.forEach((item: IUser) => {
    if (!item.createdAt) {
      dateNotAvailable.push(item);
    } else {
      dateAvailable.push(item);
    }
  });
  const array: IUser[] = sortBy(dateAvailable, [
    function (dateAvailable) {
      return dateAvailable.createdAt;
    },
  ]).reverse();

  dateNotAvailable.forEach(function (item) {
    array.push(item);
  });
  return array;
};

const sortUserByArrayByFieldForNumbers = (
  originalArray: IUser[],
  fieldName: string
) => {
  const array: IUser[] = sortBy(originalArray, [fieldName]);

  return array;
};

const sortUserByArrayByFieldForString = (
  originalArray: Record<string, any>[],
  fieldName: string
) => {
  const array: Record<string, any>[] = sortBy(originalArray, [
    function (item) {
      return item[fieldName]?.toLowerCase();
    },
  ]);

  return array as IUser[];
};

const sortUserBySuspend = (
  originalArray: IUser[],
) => {
  const array: IUser[] = originalArray.filter((item: IUser) => moment(get(item, "user.suspend.suspendTill")).diff(
    moment(new Date())) > 0)

  return array
}
