import { useEffect, useState } from "react";
import styles from "./user.module.css";
import { Link, useSearchParams } from "react-router-dom";
import { Center, Heading, Input } from "@chakra-ui/react";
import UserTableMaster from "../../Component/UserTable/UserTableMaster";

const UserMaster = () => {
  const [searchQuery, setSearch] = useState<any>("");

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("email") !== null) {
      const data = searchParams.get("email");
      setSearch(data);
    }
  }, [searchParams]);

  return (
    <>
      <div className={styles.mainOuterDiv}>
        <div className={styles.MainInnerDiv}>
          <Link to="/">
            <div className={styles.homeBtnDiv}>
              <img
                className={styles.homeIcon}
                src="/Images/User/2homebutton.png"
                alt="home"
              ></img>
            </div>
          </Link>
          <div className={styles.header3Section}>
            <div className={styles.headerSection}>
              <div className={styles.centerheader}>
                <img
                  className={styles.relativePageImage}
                  src="/Images/Home/2users.png"
                  alt="user account"
                ></img>
                <p className={styles.pageTitle}>Users</p>
              </div>
            </div>
          </div>

          <Heading size="md" fontWeight="semibold" m={"0px 20px"}>
            <Center>
              <Input
                id="search"
                backgroundColor={"white"}
                type="text"
                value={searchQuery}
                placeholder="Search Users"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              ></Input>
            </Center>
          </Heading>

          <UserTableMaster searchQuery={searchQuery} />
        </div>
      </div>
    </>
  );
};

export default UserMaster;
