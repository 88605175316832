import { FormControl, Switch, Text } from "@chakra-ui/react";
import { Field } from "formik";
import { useState } from "react";
import styles from "./SwitchButton.module.css";

export interface FieldType {
  required: boolean;
  name: string;
  fieldName: string;
  error: string | undefined;
}

const SwitchButton: React.FC<FieldType> = (props) => {
  return (
    <>
      <div className={styles.center}>
        <FormControl
          className={styles.formcontrolDiv}
          isRequired={props.required}
        >
          <Text className={styles.hsText}>
            {props.name}
            <Field className={styles.hsText} name={props.fieldName}>
              {/* @ts-ignore */}
              {({ field, form }) => {
                return (
                  <Switch
                    colorScheme="teal"
                    size="md"
                    isChecked={field.value}
                    onChange={(e) => {
                      form.setFieldValue(props.fieldName, e.target.checked);
                    }}
                  />
                );
              }}
            </Field>
          </Text>
        </FormControl>
      </div>
    </>
  );
  //   return (
  //     <div className={styles.center}>
  //       <FormControl className={styles.formcontrolDiv} isRequired={props.required}>
  //         <Text className={styles.hsText}>
  //           {props.name}
  //           <Switch
  //             colorScheme="teal"
  //             size="md"

  //             onChange={(e) => {
  //                 setSwitchValue(!switchValue)
  //             }}
  //             isChecked={props.value}
  //           />
  //         </Text>
  //       </FormControl>
  //     </div>
  //   );
};

export default SwitchButton;
