import { useToast } from "@chakra-ui/react";
import { get } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { ListManager } from "react-beautiful-dnd-grid";
import { useDispatch } from "react-redux";
import styles from "./CategoryData.module.css"
import { REQUEST_LINK_CATEGORY, REQUEST_UPDATE_LINK_CATEGORY_INDEX } from "../../../store/linkCategory/linkCategoryActionType";
import { useLinkCategories } from "../../../store/linkCategory/reducer";
import { ILinksCategory } from "../../../types/linksCategory";
import LinkCategoryDragNDrop from "../LinkCategoryDragNDrop/LinkCategoryDragNDrop"

export interface Props {
  searchQuery: string;
}

const CategoryData: React.FC<Props> = ({ searchQuery: query }) => {
  const dispatch = useDispatch();
  const toast = useToast()
  const [sortedList, setSortedList] = useState<any>();
  const searchColumns = useMemo(() => ["title"], []);
  const { linkCategory, busy: linkCategoryBusy } = useLinkCategories();


  if (!linkCategoryBusy && !(linkCategory || []).length) {
    dispatch({ type: REQUEST_LINK_CATEGORY });
  }

  const search = (linkCategory: Record<string, any>) => {
    return searchColumns.some((column) => {
      return (
        get(linkCategory, column, "")
          .toString()
          .toLowerCase()
          .indexOf(query.toLowerCase()) > -1
      );
    });
  };


  useEffect(() => {

    const data = linkCategory.map((item: ILinksCategory, index: number) => {
      return { ...item, order: index }
    })

    setSortedList(data)
  }, [linkCategory])

  if (!sortedList) {
    return <p>Loading...</p>
  }

  const sortList = () => {
    if (query === "") {
      const newArray = sortedList
        .sort((a: any, b: any) => a.order - b.order)
        .map((list: any, index: number) => {
          let obj = { ...list, index: index };
          return obj;
        });

      setSortedList([...newArray]);
      const newArrayId = newArray.map((item: ILinksCategory, index: number) => {
        return { _id: item._id, index };
      });
      const data = {
        payload: {
          category: newArrayId
        }
      }
      //API should called here
      dispatch({
        type: REQUEST_UPDATE_LINK_CATEGORY_INDEX, data
      });
    }
    else {
      toast({
        title: "Can't update",
        description: "When you search you can't change Order",
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  };

  const noop = function (sourceIndex: any, destinationIndex: any) {
    if (destinationIndex === sourceIndex) {
      return;
    }
    if (destinationIndex === 0) {
      sortedList[sourceIndex].order = sortedList[0].order - 1;
      sortList();
      return;
    }

    if (destinationIndex === sortedList.length - 1) {
      sortedList[sourceIndex].order =
        sortedList[sortedList.length - 1].order + 1;
      sortList();
      return;
    }
    if (destinationIndex < sourceIndex) {
      sortedList[sourceIndex].order =
        (sortedList[destinationIndex].order +
          sortedList[destinationIndex - 1].order) /
        2;

      // setSortedList

      sortList();
      return;
    }
    sortedList[sourceIndex].order =
      (sortedList[destinationIndex].order +
        sortedList[destinationIndex + 1].order) /
      2;

    sortList();
  };
  return (
    <>
      <div className={styles.catUpDiv}>
        <ListManager
          items={sortedList.filter(search) as ILinksCategory[]}
          direction="horizontal"
          maxItems={3}
          render={(linkCategory: ILinksCategory) => <LinkCategoryDragNDrop linkCategory={linkCategory} />}
          onDragEnd={noop}
        />
      </div>
    </>
  )
}

export default CategoryData