import { GridItem } from "@chakra-ui/react";
import { AES } from "crypto-js";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ILinks } from "../../../types/links";
import { LinksForm } from "../LinksForm/LinksForm";
import styles from "./LinksDetailComponents.module.css";

interface Props {
  link: ILinks;
  index?: number;
}

const LinksDetailComponents: React.FC<Props> = ({ link, index }) => {
  const normalToken =
    process.env.REACT_APP_AES_KEY !== undefined
      ? AES.encrypt(
          (link._id || "").toString(),
          process.env.REACT_APP_AES_KEY
        ).toString()
      : (link._id || "").toString();
  const token = window.btoa(normalToken);
  return (
    <>
      <Link to={`update/${token}`}>
        <GridItem className={styles.appBox}>
          <div className={styles.appDetailDiv}>
            <img
              src={link.logo}
              alt={link.title}
              className={styles.appImg}
            ></img>
            <p className={styles.appName}> {link.title} </p>
          </div>
        </GridItem>
      </Link>
    </>
  );
};

export default LinksDetailComponents;
