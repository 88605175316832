import { AES } from "crypto-js";
import React from "react";
import { Link } from "react-router-dom";
import { ILinks } from "../../../types/links";
import styles from "./LinksDragNDrop.module.css";
import { Grid, GridItem } from "@chakra-ui/react";

interface LinksDragNDropPropsType {
  link: ILinks;
}

const LinksDragNDrop: React.FC<LinksDragNDropPropsType> = ({ link }) => {
  const normalToken =
    process.env.REACT_APP_AES_KEY !== undefined
      ? AES.encrypt(
          (link._id || "").toString(),
          process.env.REACT_APP_AES_KEY
        ).toString()
      : (link._id || "").toString();
  const token = window.btoa(normalToken);
  return (
    <>
      <Link to={`update/${token}`}>
        <div className={styles.socialIconDivDrag}>
          <img src={link.logo} className={styles.appImg} alt={link.logo} />
          <p className={styles.appName}> {link.title} </p>
        </div>
      </Link>
    </>
  );
};

export default LinksDragNDrop;
