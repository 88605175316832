import styles from "./home.module.css";
import { Link } from "react-router-dom";

interface Home {
  props: any;
  key: any;
}

const HomeComponents: React.FC<Home> = ({ props }) => {
  const classNames = props.className.map((value: any) => {
    return styles[value];
  });

  const className = classNames.toString().replace(",", " ");
  return (
    <>
      <div className={styles.ParticularImgDiv}>
        <Link to={props.link} className={styles.botheContaine}>
          <img
            src={props.src}
            className={`${styles.icon}`}
            alt={props.title}
          ></img>
          <p className={`${className}`}>{props.title}</p>
        </Link>
      </div>
    </>
  );
};

export default HomeComponents;
