import React, { useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
interface Props {
  onChangeEditor?: (value: string) => void;
}

export const Editor: React.FC<Props> = ({ onChangeEditor }) => {
  const [aa, setAA] = useState("");
  return (
    <ReactQuill
      style={{
        width: "90%",
        border: "1px solid #3a8476",
        borderRadius: "10px",
        overflow: "scroll",
        height: "200px",
      
      }}
      id={"editor"}
      value={aa}
      onChange={(e) => {
        setAA(e);
        onChangeEditor && onChangeEditor(e);
      }}
      theme="snow"
    />
  );
};
