import { useSelector } from "react-redux";
import { IUser } from "../../types/user";
import {
  ERROR_SUSPEND_USER,
  ERROR_UNSUSPEND_USER,
  REQUEST_MESSAGE_NULL,
  REQUEST_SUSPEND_USER,
  REQUEST_UNSUSPEND_USER,
  SET_MESSAGE_NULL,
  SET_SUSPEND_USER,
} from "./userActionsActionType";

const initialState: { busy: false; message: ""; userAction: IUser[] } = {
  busy: false,
  message: "",
  userAction: [],
};
const userActions = (state = initialState, action: Record<string, any>) => {
  switch (action.type) {
    case REQUEST_SUSPEND_USER:
    case REQUEST_UNSUSPEND_USER:
    case REQUEST_MESSAGE_NULL:
      return {
        ...state,
        busy: true,
        message: "",
      };
    case SET_MESSAGE_NULL:
      return {
        ...state,
        message: "",
        busy: false,
      };
    case SET_SUSPEND_USER:
      return {
        ...state,
        busy: false,
        message: "",
      };
    case ERROR_SUSPEND_USER:
    case ERROR_UNSUSPEND_USER:
      return {
        ...state,
        busy: false,
        message:
          action.payload || "Something happened wrong try again after sometime",
      };
    default:
      return state;
  }
};
export default userActions;

export function useUserActions() {
  return useSelector((state: Record<string, any>) => state.userActions);
}
