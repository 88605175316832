import { useState } from "react";
import styles from "./LinksMaster.module.css";
import { Link } from "react-router-dom";

import { Center, Heading, Input } from "@chakra-ui/react";

import { useDispatch } from "react-redux";
import { AiOutlinePlus } from "react-icons/ai";
import LinksData from "../../Component/linksDetails/LinksData/LinksData";
import { REQUEST_MASTER_LINKS } from "../../store/linkMaster/linkActionTypes";
import { useLinksMaster } from "../../store/linkMaster/reducer";
import { useLinkCategories } from "../../store/linkCategory/reducer";
import { REQUEST_LINK_CATEGORY } from "../../store/linkCategory/linkCategoryActionType";

const LinksMaster = () => {
  const [searchQuery, setSearch] = useState("");
  const { masterLinks, busy: linkBusy } = useLinksMaster();

  const { linkCategory, busy: linkCategoryBusy } = useLinkCategories();

  const dispatch = useDispatch();
  if (!linkBusy && !(masterLinks || []).length) {
    dispatch({ type: REQUEST_MASTER_LINKS });
  }

  if (!linkCategoryBusy && !(linkCategory || []).length) {
    dispatch({ type: REQUEST_LINK_CATEGORY });
  }

  return (
    <>
      <div className={styles.mainOuterDiv}>
        <div className={styles.MainInnerDiv}>
          <Link to="/">
            <div className={styles.homeBtnDiv}>
              <img
                className={styles.homeIcon}
                src="/Images/User/2homebutton.png"
                alt="home"
              ></img>
            </div>
          </Link>
          <div className={styles.header3Section}>
            <div className={styles.headerSection}>
              <div className={styles.centerheader}>
                <img
                  className={styles.relativePageImage}
                  src="/Images/Home/3links.png"
                  alt="link"
                ></img>
                <p className={styles.pageTitle}>Links</p>
              </div>
              <div>
                <Link to={`/category`}>
                  <div className={styles.addLinkBtnDiv1}>
                    <button className={styles.addLinkBtn}> Categories</button>
                  </div>
                </Link>
                <Link to={`add`}>
                  <div className={styles.addLinkBtnDiv}>
                    <AiOutlinePlus className={styles.plusicon} />
                    <button className={styles.addLinkBtn}>Add Link</button>
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <Heading size="md" fontWeight="semibold" m={"0px 20px"}>
            <Center>
              <Input
                id="search"
                type="text"
                placeholder="Search..."
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                style={{
                  border: "1px solid white",
                  width: "100%",
                  backgroundColor: "white",
                  borderRadius: "10px",
                }}
              ></Input>
            </Center>
          </Heading>

          <LinksData searchQuery={searchQuery} />
        </div>
      </div>
    </>
  );
};

export default LinksMaster;
