import HomeComponents from "../../Component/Home/Home";
import styles from "./home.module.css";
import { homeData } from "./homeData";
import { Grid, GridItem } from "@chakra-ui/react";

const Home = () => {
  return (
    <div className="MainOuterDiv">
      <div className="InnerDiv">
        <div className={styles.headerSection}>
          <img src="/Images/1logo.png" alt="Home"></img>
          <h3 className={styles.DashboardTitle}>Home</h3>
        </div>

        <div className={styles.optionSection}>
          {homeData.map((value: any) => {
            return <HomeComponents props={value} key={value.title} />;
          })}
        </div>
      </div>
    </div>
  );
};
export default Home;
