import { useSelector } from "react-redux";
import { IFeedBack } from "../../types/feedback";
import {
  REQUEST_FEEDBACK_LIST,
  SET_FEEDBACK_LIST,
  REQUEST_SEND_ADMIN_FEEDBACK,
  ERROR_SEND_ADMIN_FEEDBACK,
  SUCCESS_REPLY_SEND,
} from "./feedbackActionType";

interface IState {
  busy: boolean;
  message: string;
  feedback: IFeedBack[];
  replySend: boolean;
}

const initialState: IState = {
  busy: false,
  message: "",
  feedback: [],
  replySend: false,
};

const feedbacks = (state = initialState, action: Record<string, any>) => {
  switch (action.type) {
    case REQUEST_SEND_ADMIN_FEEDBACK:
    case REQUEST_FEEDBACK_LIST:
      return {
        ...state,
        busy: true,
        replySend: false,
        message: "",
      };

    case SET_FEEDBACK_LIST:
      return {
        ...state,
        busy: false,
        replySend: false,
        message: "",
        feedback: action.payload,
      };

    case SUCCESS_REPLY_SEND:
      return {
        ...state,
        busy: false,
        replySend: !state.replySend,
      };

    case ERROR_SEND_ADMIN_FEEDBACK:
      return {
        ...state,
        busy: false,
        replySend: false,
        message:
          action.payload === "none"
            ? ""
            : action.payload ||
              "Something happened wrong try again after sometime",
      };
    default:
      return state;
  }
};
export default feedbacks;

export function useFeedback() {
  return useSelector((state: Record<string, any>) => state.feedbacks);
}
