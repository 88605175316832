import styles from "./Suspend.module.css";
import {
  Button,
  Center,
  Flex,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { IoCalendarOutline } from "react-icons/io5";
import moment from "moment";

import { get } from "lodash";
import { useAccounts } from "../../../../store/account/reducer";
import { useUserActions } from "../../../../store/userActions/reducer";
import { useSuspendMessageType } from "../../../../store/suspendType/reducer";
import { IAccount } from "../../../../types/account";
import { ISuspendType } from "../../../../types/suspendType";
import { useDispatch } from "react-redux";
import { AES, enc } from "crypto-js";
import { REQUEST_SUSPEND_USER } from "../../../../store/userActions/userActionsActionType";
import { Calender } from "../../../Calender/Calender";
import { Editor } from "../../../Editior/Editor";

interface Props {
  isOpen: any;
  onClose: any;
}

const Suspend: React.FC<Props> = ({ isOpen, onClose }) => {
  const { accounts } = useAccounts();
  const { message: userActionMessage, busy: userActionBusy } = useUserActions();
  const {
    suspendMessageType,
    busy: suspendTypeBusy,
    message: suspendMessageTypeMessage,
  } = useSuspendMessageType();

  const [account, setAccount] = useState<IAccount>();
  const [suspensionDate, setSuspensionDateChange] = useState(new Date());
  const [selectedSuspendReason, setSelectedSuspendReason] =
    useState<ISuspendType>();
  const [suspendMessage, setSuspendMessage] = useState("");
  const [selectedSuspendDate, setSelectedSuspendDate] = useState(false);
  const dispatch = useDispatch();
  const toast = useToast();


  useEffect(() => {
    const originalUrl = window.location.pathname.split("/");
    const aesToken = window.atob(originalUrl[originalUrl.length - 1]);
    const accountId =
      process.env.REACT_APP_AES_KEY !== undefined
        ? AES.decrypt(aesToken, process.env.REACT_APP_AES_KEY).toString(
            enc.Utf8
          )
        : aesToken.toString();
    setAccount(accounts.find((ac: IAccount) => ac._id === accountId));
  }, [accounts]);

  const onSubmit = () => {
    if (suspendMessage === "") {
      toast({
        title: "Enter Message",
        description: "Message field is empty",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } else {
      const data = {
        _id: get(account, "user._id"),
        suspend: {
          suspendTill: moment(suspensionDate).utc().toISOString(true),
          suspendMessage: suspendMessage,
          suspendType: selectedSuspendReason?._id,
        },
      };
      dispatch({
        type: REQUEST_SUSPEND_USER,
        data,
        onSuccess: () => {
          onClose();
        },
      });
    }
  };

  useEffect(() => {
    if (userActionMessage) {
      toast({
        title: "Please Try again",
        description: userActionMessage,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    }
  }, [toast, userActionMessage, userActionBusy]);

  if (suspendMessageType && !suspendMessageType.length) {
    return <div>loading</div>;
  } else if (!account) {
    return <div>account not found...</div>;
  }


  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={"xs"}
        closeOnOverlayClick={false}
        id="suspendBoxDiv"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text fontSize="lg">
              {selectedSuspendReason
                ? "Select Reason For Suspension"
                : !selectedSuspendDate
                ? "Suspend User Till"
                : "Enter Message For Suspend"}
            </Text>
          </ModalHeader>
          {!userActionBusy ? <ModalCloseButton /> : null}
          <ModalBody id="modalBodyOfReasons">
            {selectedSuspendReason === undefined ? (
              <div className={styles.mainPopupDiv}>
                <div className={styles.innerPopupDiv}>
                  <div className={styles.Reasons}>
                    {suspendMessageType.map((value: any) => {
                      return (
                        <button
                          key={value.reason}
                          className={styles.reasonText}
                          onClick={() => {
                            setSelectedSuspendReason(value);
                          }}
                        >
                          {value.reason}
                        </button>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : !selectedSuspendDate ? (
              <>
                <button className={styles.reasonText}>
                  {selectedSuspendReason.reason}
                </button>

                <Calender
                  onChangeDate={(date: Date, change: boolean) => {
                    var ms = moment(date, "DD/MM/YYYY HH:mm:ss").diff(
                      moment(new Date(), "DD/MM/YYYY HH:mm:ss")
                    );
                    if (ms < 0) {
                      toast({
                        title: "Select at least One day",
                        description: "User can suspend At least One Day",
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                        position: "top",
                      });
                    } else {
                      setSuspensionDateChange(date);
                      setSelectedSuspendDate(change);
                    }
                  }}
                />
              </>
            ) : (
              <div className={styles.mainPopupDiv}>
                <div className={styles.innerPopupDiv}>
                  <button className={styles.reasonText}>
                    {selectedSuspendReason.reason}
                  </button>

                  <Flex className={styles.reasonText}>
                    <HStack>
                      <Center>
                        {/* <p className={styles.beforeClickCal}>Select Suspension Expiration Date</p> */}
                        <Text fontSize={"lg"} pr={3}>
                          {moment(suspensionDate.toString()).format(
                            "MMMM Do, YYYY"
                          )}
                        </Text>
                        <Spacer></Spacer>
                        <Icon
                          as={IoCalendarOutline}
                          fontSize="xl"
                          color="gray.500"
                          onClick={() => {
                            setSelectedSuspendDate(false);
                          }}
                        />
                        {/* <IoCalendarOutline className={styles.calIcon} p={2} /> */}
                      </Center>
                    </HStack>
                  </Flex>

                  <Editor
                    onChangeEditor={(value: string) => {
                      setSuspendMessage(value);
                    }}
                  />
                  {userActionBusy ? (
                    <Spinner
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="blue.500"
                      size="lg"
                      mt={5}
                    />
                  ) : (
                    <Button
                      mt={3}
                      mb={2}
                      colorScheme={"blue"}
                      onClick={() => {
                        onSubmit();
                      }}
                    >
                      Save
                    </Button>
                  )}
                </div>
              </div>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Suspend;