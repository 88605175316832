import axios from "axios";
import { get } from "lodash";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  ERROR_CREATE_LINK_CATEGORY,
  ERROR_UPDATE_LINK_CATEGORY,
  ERROR_UPDATE_LINK_CATEGORY_FOR_LINK_ORDER,
  ERROR_UPDATE_LINK_CATEGORY_INDEX,
  REQUEST_CREATE_LINK_CATEGORY,
  REQUEST_LINK_CATEGORY,
  REQUEST_UPDATE_LINK_CATEGORY,
  REQUEST_UPDATE_LINK_CATEGORY_FOR_LINK_ORDER,
  REQUEST_UPDATE_LINK_CATEGORY_INDEX,
  SET_LINK_CATEGORY,
  SET_UPDATE_LINK_CATEGORY,
  SET_UPDATE_LINK_CATEGORY_FOR_LINK_ORDER,
  SET_UPDATE_LINK_CATEGORY_INDEX,
  SUCCESS_CREATE_LINK_CATEGORY,
} from "./linkCategoryActionType";

function* requestLinkCategory(action: Record<string, any>): any {
  try {
    const result: any = yield call(getLinkCategory);
    yield put({ type: SET_LINK_CATEGORY, payload: result.data });
  } catch (err) {
    console.error(`Error fetching switched account`, action);
  }
}

function* requestLinkCategoryUpdateLinkOrder(action: Record<string, any>): any {
  try {
    const result: any = yield call(
      updateLinkCategoryUpdateLinkOrder,
      action.data.payload,
      action.data._id
    );
    yield put({
      type: SET_UPDATE_LINK_CATEGORY_FOR_LINK_ORDER,
      payload: result.data,
    });
  } catch (error: any) {
    let message =
      "Something went wrong, please try again after some time or Refresh the Page.";
    if (get(error, "response.status") === 500) {
      message = "Something happened wrong try again after sometime.";
    } else if (get(error, "response.status") === 422) {
      message = "please provide valid contain";
    } else if (get(error, "response.status") === 415) {
      message = error.response.data.message;
    }
    yield put({ type: ERROR_UPDATE_LINK_CATEGORY_FOR_LINK_ORDER, payload: message });
  }
}

function* requestLinkCategoryUpdateOrder(action: Record<string, any>): any {
  try {
    const result: any = yield call(
      updateLinkCategoryUpdateOrder,
      action.data.payload,
    );
    yield put({
      type: SET_UPDATE_LINK_CATEGORY_INDEX,
      payload: result.data,
    });
  } catch (error: any) {
    let message =
      "Something went wrong, please try again after some time or Refresh the Page.";
    if (get(error, "response.status") === 500) {
      message = "Something happened wrong try again after sometime.";
    } else if (get(error, "response.status") === 422) {
      message = "please provide valid contain";
    } else if (get(error, "response.status") === 415) {
      message = error.response.data.message;
    }
    yield put({ type: ERROR_UPDATE_LINK_CATEGORY_INDEX, payload: message });
  }
}

function* requestCreateLinkCategory(action: Record<string, any>): any {
  try {
    const result = yield call(createLinkCategory, action.payload);
    yield put({
      type: SUCCESS_CREATE_LINK_CATEGORY,
      payload: result.data,
    });

    action && action.onSuccess && action.onSuccess();

    // update relevant category as well
  } catch (error: any) {
    let message =
      "Something went wrong, please try again after some time or Refresh the Page.";
    if (get(error, "response.status") === 500) {
      message = "Something happened wrong try again after sometime.";
    } else if (get(error, "response.status") === 422) {
      message = error.response.data.message || "please provide valid contain";
    } else if (get(error, "response.status") === 415) {
      message = error.response.data.message;
    }
    yield put({ type: ERROR_CREATE_LINK_CATEGORY, payload: message });
  }
}

function* requestUpdateLinkCategory(action: Record<string, any>): any {
  try {
    const result = yield call(updateLinkCategory, action.data.payload,
      action.data._id);
    yield put({
      type: SET_UPDATE_LINK_CATEGORY,
      payload: result.data,
    });

    action && action.onSuccess && action.onSuccess();

    // update relevant category as well
  } catch (error: any) {
    let message =
      "Something went wrong, please try again after some time or Refresh the Page.";
    if (get(error, "response.status") === 500) {
      message = "Something happened wrong try again after sometime.";
    } else if (get(error, "response.status") === 422) {
      message = error.response.data.message || "please provide valid contain";
    } else if (get(error, "response.status") === 415) {
      message = error.response.data.message;
    }
    yield put({ type: ERROR_UPDATE_LINK_CATEGORY, payload: message });
  }
}

export function getLinkCategory() {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL}/links_category/`,
    withCredentials: true,
  });
}

export function updateLinkCategoryUpdateLinkOrder(
  payload: Record<string, any>,
  _id: string
) {
  return axios({
    method: "patch",
    url: `${process.env.REACT_APP_BASE_URL}/links_category/updateLinkOrder/${_id}`,
    data: payload,
    withCredentials: true,
  });
}

export function updateLinkCategoryUpdateOrder(
  payload: Record<string, any>,
) {
  return axios({
    method: "patch",
    url: `${process.env.REACT_APP_BASE_URL}/links_category/updateCategoryIndex/`,
    data: payload,
    withCredentials: true,
  });
}

export function createLinkCategory(payload: Record<string, any>) {
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_BASE_URL}/links_category/`,
    data: payload,
    withCredentials: true,
  });
}

export function updateLinkCategory(payload: Record<string, any>, _id: string) {
  return axios({
    method: "patch",
    url: `${process.env.REACT_APP_BASE_URL}/links_category/${_id}`,
    data: payload,
    withCredentials: true,
  });
}

const linkCategorySaga = function* () {
  yield all([
    takeLatest(REQUEST_LINK_CATEGORY, requestLinkCategory),
    takeLatest(
      REQUEST_UPDATE_LINK_CATEGORY_FOR_LINK_ORDER,
      requestLinkCategoryUpdateLinkOrder
    ),
    takeLatest(REQUEST_UPDATE_LINK_CATEGORY_INDEX, requestLinkCategoryUpdateOrder),
    takeLatest(REQUEST_CREATE_LINK_CATEGORY, requestCreateLinkCategory),
    takeLatest(REQUEST_UPDATE_LINK_CATEGORY, requestUpdateLinkCategory),

  ]);
};

export default linkCategorySaga;
