import { get } from "lodash";
import { useSelector } from "react-redux";
import { ILinks } from "../../types/links";
import {
  ERROR_CREATE_MASTER_LINK,
  ERROR_DEACTIVE_MASTER_LINK,
  ERROR_DELETE_MASTER_LINK,
  ERROR_UPDATE_MASTER_LINK,
  REQUEST_CREATE_MASTER_LINK,
  REQUEST_DEACTIVE_MASTER_LINK,
  REQUEST_DELETE_MASTER_LINK,
  REQUEST_MASTER_LINKS,
  REQUEST_UPDATE_MASTER_LINK,
  SET_MASTER_LINKS,
  SUCCESS_CREATE_MASTER_LINK,
  SUCCESS_DEACTIVE_MASTER_LINK,
  SUCCESS_DELETE_MASTER_LINK,
  SUCCESS_UPDATE_MASTER_LINK,
} from "./linkActionTypes";

const initialState: { busy: boolean; message: string; masterLinks: ILinks[] } =
  {
    busy: false,
    message: "",
    masterLinks: [],
  };
const links = (state = initialState, action: Record<string, any>) => {
  switch (action.type) {
    case REQUEST_MASTER_LINKS:
    case REQUEST_DELETE_MASTER_LINK:
    case REQUEST_DEACTIVE_MASTER_LINK:
    case REQUEST_CREATE_MASTER_LINK:
    case REQUEST_UPDATE_MASTER_LINK:
      return {
        ...state,
        busy: true,
        message: "",
      };

    case SET_MASTER_LINKS:
      return {
        ...state,
        busy: false,
        message: "",
        masterLinks: action.payload,
      };

    case SUCCESS_CREATE_MASTER_LINK:
      return {
        ...state,
        busy: false,
        message: "",
        masterLinks: [...state.masterLinks, action.payload],
      };

    case SUCCESS_UPDATE_MASTER_LINK:
      return {
        ...state,
        busy: false,
        message: "",
        masterLinks: state.masterLinks.map((ml) => {
          return get(ml, "_id") === action.payload._id ? action.payload : ml;
        }),
      };

    case SUCCESS_DELETE_MASTER_LINK:
      return {
        ...state,
        busy: false,
        message: "",
        masterLinks: state.masterLinks.filter(
          (link) => get(link, "_id") !== action.payload
        ),
      };

    case SUCCESS_DEACTIVE_MASTER_LINK:
      return {
        ...state,
        busy: false,
        message: "",
        masterLinks: state.masterLinks.map((link: Record<string, any>) => {
          if (get(link, "_id") === action.payload._id) {
            return { ...link, isDeactive: action.payload.isDeactive };
          }
          return link;
        }),
      };

    case ERROR_DELETE_MASTER_LINK:
    case ERROR_DEACTIVE_MASTER_LINK:
    case ERROR_CREATE_MASTER_LINK:
    case ERROR_UPDATE_MASTER_LINK:
      return {
        ...state,
        busy: false,
        message:
          action.payload || "Something happened wrong try again after sometime",
      };
    default:
      return state;
  }
};

export default links;

export function useLinksMaster() {
  return useSelector((state: Record<string, any>) => state.linksMaster);
}
