import {
  FormControl,
  InputGroup,
  InputLeftAddon,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";
import { Field } from "formik";
import React from "react";
import styles from "./RadioFormFields.module.css";
interface Props {
  required: boolean;
  name: string;
  fieldName: string;
  error: string | undefined;
}

const RadioFormFields: React.FC<Props> = (props) => {
  return (
    <Stack spacing={4}>
      <FormControl isRequired={props.required}>
        <InputGroup className={styles.inputGroup}>
          <InputLeftAddon children={props.name} className={styles.childTitle} />

          <Field name={props.fieldName}>
            {/* @ts-ignore */}
            {({ field, form }) => {
              return (
                <RadioGroup
                  className={styles.rBtnDiv}
                  value={field.value?.toString()}
                  onChange={(e) => {
                    form.setFieldValue(props.fieldName, e);
                  }}
                >
                  <Stack direction="row">
                    <Radio value={"true"} style={{background: "#fff"}}>Yes</Radio>
                    <Radio value={"false"} style={{background: "#fff"}}>No</Radio>
                  </Stack>
                </RadioGroup>
              );
            }}
          </Field>
        </InputGroup>
      </FormControl>
    </Stack>
  );
};

export default RadioFormFields;
