import axios from "axios";
import { get } from "lodash";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { SUCCESS_USER_SUSPEND_ACCOUNT_UPDATE } from "../account/accountActionType";
import {
  ERROR_SUSPEND_USER,
  ERROR_UNSUSPEND_USER,
  REQUEST_MESSAGE_NULL,
  REQUEST_SUSPEND_USER,
  REQUEST_UNSUSPEND_USER,
  SET_MESSAGE_NULL,
  SET_SUSPEND_USER,
} from "./userActionsActionType";
function* requestSuspendUsers(action: Record<string, any>): any {
  try {
    const result: any = yield call(
      suspendUser,
      action.data.suspend,
      action.data._id
    );
    yield put({ type: SET_SUSPEND_USER });
    yield put({
      type: SUCCESS_USER_SUSPEND_ACCOUNT_UPDATE,
      payload: result.data,
    });

    action && action.onSuccess && action.onSuccess();
  } catch (error: any) {
    let message =
      "Something went wrong, please try again after some time or Refresh the Page.";
    if (get(error, "response.status") === 500) {
      message = "Something happened wrong try again after sometime.";
    } else if (get(error, "response.status") === 422) {
      message = error.response.data.message || "please provide valid contain";
    } else if (get(error, "response.status") === 415) {
      message = error.response.data.message;
    }
    yield put({ type: ERROR_SUSPEND_USER, payload: message });
  }
}

function* requestUNSuspendUsers(action: Record<string, any>): any {
  try {
    const result: any = yield call(UnuspendUser, action.data._id);
    // yield put({ type: SET_SUSPEND_USER, payload: result.data });
    yield put({
      type: SUCCESS_USER_SUSPEND_ACCOUNT_UPDATE,
      payload: result.data,
    });
    action && action.onSuccess && action.onSuccess();

  } catch (error: any) {
    let message =
      "Something went wrong, please try again after some time or Refresh the Page.";
    if (get(error, "response.status") === 500) {
      message = "Something happened wrong try again after sometime.";
    } else if (get(error, "response.status") === 422) {
      message = error.response.data.message || "please provide valid contain";
    } else if (get(error, "response.status") === 415) {
      message = error.response.data.message;
    }
    yield put({ type: ERROR_UNSUSPEND_USER, payload: message });
  }
}

function* requestMessageNull() {
  yield put({
    type: SET_MESSAGE_NULL,
  });
}

export function suspendUser(payload: Record<string, any>, _id: string) {
  const suspend = {
    suspend: {
      suspendTill: payload.suspendTill,
      suspendMessage: payload.suspendMessage,
      suspendType: payload.suspendType,
    },
  };
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_BASE_URL}/user/suspendUser/${_id}`,
    data: suspend,
    withCredentials: true,
  });
}

export function UnuspendUser(_id: string) {
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_BASE_URL}/user/unSuspendUser/${_id}`,
    withCredentials: true,
  });
}
const userActionSaga = function* () {
  yield all([
    takeLatest(REQUEST_SUSPEND_USER, requestSuspendUsers),
    takeLatest(REQUEST_UNSUSPEND_USER, requestUNSuspendUsers),
    takeLatest(REQUEST_MESSAGE_NULL, requestMessageNull),
  ]);
};
export default userActionSaga;
