export const REQUEST_LINK_CATEGORY = "REQUEST_LINK_CATEGORY";
export const SET_LINK_CATEGORY = "SET_LINK_CATEGORY";

export const REQUEST_UPDATE_LINK_CATEGORY =
  "REQUEST_UPDATE_LINK_CATEGORY";
export const SET_UPDATE_LINK_CATEGORY =
  "SET_UPDATE_LINK_CATEGORY";
export const ERROR_UPDATE_LINK_CATEGORY =
  "ERROR_UPDATE_LINK_CATEGORY";

export const REQUEST_UPDATE_LINK_CATEGORY_FOR_LINK_ORDER =
  "REQUEST_UPDATE_LINK_CATEGORY_FOR_LINK_ORDER";
export const SET_UPDATE_LINK_CATEGORY_FOR_LINK_ORDER =
  "SET_UPDATE_LINK_CATEGORY_FOR_LINK_ORDER";
export const ERROR_UPDATE_LINK_CATEGORY_FOR_LINK_ORDER =
  "ERROR_UPDATE_LINK_CATEGORY_FOR_LINK_ORDER";

export const REQUEST_UPDATE_LINK_CATEGORY_INDEX =
  "REQUEST_UPDATE_LINK_CATEGORY_INDEX";
export const SET_UPDATE_LINK_CATEGORY_INDEX =
  "SET_UPDATE_LINK_CATEGORY_INDEX";
export const ERROR_UPDATE_LINK_CATEGORY_INDEX =
  "ERROR_UPDATE_LINK_CATEGORY_INDEX";


export const REQUEST_CREATE_LINK_CATEGORY = "REQUEST_CREATE_LINK_CATEGORY";
export const SUCCESS_CREATE_LINK_CATEGORY = "SUCCESS_CREATE_LINK_CATEGORY";
export const ERROR_CREATE_LINK_CATEGORY = "ERROR_CREATE_LINK_CATEGORY";