import React, { useMemo } from "react";
import styles from "./Calender.module.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Box } from "@chakra-ui/react";

interface Props {
  onChangeDate?: (date: Date, selectedSuspendDate: boolean) => void;
}

export const Calender: React.FC<Props> = ({
  onChangeDate,
}) => {
  const currentDate = useMemo(() => new Date(), []);
  return (
    <Box mt={5} className={styles.innerPopupDiv}>
      <Calendar
        onChange={(v: Date) => {
          onChangeDate && onChangeDate(v, true);
        }}
        className={styles.cal}
        value={currentDate}
        minDate={currentDate}
      />
    </Box>
  );
};
