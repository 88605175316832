import React, { useState } from "react";
import styles from "./LinksForm.module.css"


const Button = props => {
   const clickHandler = () => {
      props.setActiveButton(props.id)
   }
   return (
      <button
         type="reset"
         className={` ${styles.probtn} ${props.active ? styles.probtnActive : null} `}
         value={props.value}
         onClick={clickHandler} >
         {props.value}
      </button>
   )

}

export default Button