import React, { useMemo, useEffect } from "react";
import styles from "./Calender.module.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Box, useToast } from "@chakra-ui/react";
import { Flex, FormControl, FormLabel, HStack, Spacer } from "@chakra-ui/react";
import { Field } from "formik";
import moment from "moment";

export interface FieldType {
  required: boolean;
  name: string;
  value?: Date;
  fieldName: string;
  disabled?: boolean;
  error: string | undefined;
  placeholder?: string;
}

export const CalenderFormField: React.FC<FieldType> = (props) => {
  const initialDate = useMemo(() => {
    if (props.value) {
      return new Date(props.value);
    }
    return new Date();
  }, [props]);
  const currentDate = useMemo(() => new Date(), []);

  useEffect(() => {}, [props]);

  const toast = useToast();

  return (
    <FormControl isRequired={props.required}>
      <Flex width="100%">
        <HStack>
          <FormLabel>{props.name}</FormLabel>
          <Spacer></Spacer>
          <p className={styles.error}>{props.error}</p>
        </HStack>
      </Flex>
      <Field
        name={props.fieldName}
        className={props.error ? styles.errorField : styles.field}
      >
        {/* @ts-ignore */}
        {({ field, meta: { touched }, form }) => {
          return (
            <Box mt={5} className={styles.innerPopupDiv}>
              <Calendar
                onChange={(v: any) => {
                  var ms = moment(v, "DD/MM/YYYY HH:mm:ss").diff(
                    moment(currentDate, "DD/MM/YYYY HH:mm:ss")
                  );
                  if (ms <= 0) {
                    toast({
                      title: "Select at least One day",
                      description: "User can suspend At least One Day",
                      status: "error",
                      duration: 3000,
                      isClosable: true,
                      position: "top",
                    });
                  } else {
                    // @ts-ignore
                    form.setFieldValue(props.fieldName, new Date(v).getTime());
                  }
                }}
                // {...field}
                className={styles.cal}
                maxDate={moment(initialDate).add(5, "years").toDate()}
                // disabled={true}
                value={initialDate}
                // errorBorderColor={"red"}

                minDate={currentDate}
              />
            </Box>
          );
        }}
      </Field>
    </FormControl>
  );
};
