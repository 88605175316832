import { AES } from "crypto-js";
import React from "react";
import { Link } from "react-router-dom";
import styles from "./LinkCategoryDragNDrop.module.css";
import { ILinksCategory } from "../../../types/linksCategory";
import { useDisclosure } from "@chakra-ui/react";
import AddLinksCategory from "../../modules/LinkCategory/AddLinksCategory";

interface LinkCategoryDragNDrop {
  linkCategory: ILinksCategory;
}

const LinkCategoryDragNDrop: React.FC<LinkCategoryDragNDrop> = ({ linkCategory }) => {

  const {
    isOpen: isAddOpen,
    onOpen: onAddOpen,
    onClose: onAddClose,
  } = useDisclosure();

  return (
    <>

      <div className={styles.socialIconDivDrag} onClick={onAddOpen}>
        <div></div>
        <img src="/Images/profile.png" className={styles.appImg} alt="logo" />
        <p className={styles.appName}> {linkCategory.title} </p>
      </div>
      {isAddOpen && (
        <AddLinksCategory
          linkCategory={linkCategory}
          isOpen={isAddOpen} onClose={onAddClose} />
      )}
    </>
  );
};

export default LinkCategoryDragNDrop;
