import axios from "axios";
import { get } from "lodash";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  ERROR_SUSPEND_TYPE,
  REQUEST_SUSPEND_TYPE,
  SET_SUSPEND_TYPE,
} from "./suspendTypeActionTypes";

function* requestSuspendMessageType(): any {
  try {
    const result: any = yield call(getSuspendMessageType);
    yield put({ type: SET_SUSPEND_TYPE, payload: result.data });
  } catch (error: any) {
    let message =
      "Something went wrong, please try again after some time or Refresh the Page.";
    if (get(error, "response.status") === 500) {
      message = "Something happened wrong try again after sometime.";
    } else if (get(error, "response.status") === 403) {
      message = error.response.data.message;
    }
    yield put({ type: ERROR_SUSPEND_TYPE, payload: message });
  }
}
export function getSuspendMessageType() {
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_BASE_URL}/suspend_type`,
    withCredentials: true,
  });
}
const suspendTypeSaga = function* () {
  yield all([takeLatest(REQUEST_SUSPEND_TYPE, requestSuspendMessageType)]);
};
export default suspendTypeSaga;
