import { get, update } from "lodash";
import { useSelector } from "react-redux";
import { IAccount } from "../../types/account";
import {
  ERROR_DELETE_ACCOUNT,
  ERROR_GET_ACCOUNTS,
  ERROR_UPDATE_ACCOUNT,
  REQUEST_ACCOUNTS,
  REQUEST_UPDATE_ACCOUNT,
  SET_ACCOUNTS,
  SET_DELETE_ACCOUNT,
  SUCCESS_UPDATE_ACCOUNT,
  SUCCESS_USER_SUSPEND_ACCOUNT_UPDATE,
  REQUEST_ACCOUNT_VERIFY,
  SUCCESS_ACCOUNT_VERIFY,
  ERROR_ACCOUNT_VERIFY,
  PRO_USER_SUCCESS_ACCOUNT,
} from "./accountActionType";

const initialState: {
  busy: boolean;
  message: string;
  accounts: IAccount[];
} = {
  busy: false,
  message: "",
  accounts: [],
};

const accounts = (state = initialState, action: Record<string, any>) => {
  switch (action.type) {
    case REQUEST_ACCOUNTS:
    case REQUEST_UPDATE_ACCOUNT:
    case REQUEST_ACCOUNT_VERIFY:
      return {
        ...state,
        message: "",
        busy: true,
      };

    case SET_ACCOUNTS:
      return {
        ...state,
        busy: false,
        message: "",
        accounts: [...state.accounts, ...action.payload],
      };

    case SET_DELETE_ACCOUNT:
      return {
        ...state,
        busy: false,
        message: "",
        accounts: state.accounts.filter(
          (item: IAccount) => get(item, "_id") !== action.payload
        ),
      };

    case SUCCESS_UPDATE_ACCOUNT:
      return {
        ...state,
        busy: false,
        message: "",
        accounts: state.accounts.map((item: IAccount) => {
          if (get(item, "_id") === action.payload._id.toString()) {
            return action.payload;
          }
          return item;
        }),
      };

    case SUCCESS_ACCOUNT_VERIFY:
      return {
        ...state,
        busy: false,
        message: "",
        accounts: state.accounts.map((account: IAccount) => {
          if (get(account, "_id") === get(action.payload, "accountId")) {
            return {
              ...account,
              isVerify: !get(account, "isVerify"),
            };
          }
          return account;
        }),
      };

    case SUCCESS_USER_SUSPEND_ACCOUNT_UPDATE:
      return {
        ...state,
        busy: false,
        message: "",
        accounts: state.accounts.map((item: IAccount) => {
          if (get(item, "user._id") === action.payload._id) {
            update(item, "user.suspend", function (n) {
              return action.payload.suspend;
            });
            return item;
          } else {
            return item;
          }
        }),
      };

    case PRO_USER_SUCCESS_ACCOUNT:
      return {
        ...state,
        busy: false,
        message: "",
        accounts: state.accounts.map((account, index) => {
          if (
            get(account, "user._id").toString() ===
            get(action.payload, "userId")
          ) {
            return {
              ...account,
              user: {
                //@ts-ignore
                ...account.user,
                isPro: !get(account, "user.isPro"),
                subscriptionTill: get(
                  action.payload,
                  "payload.subscriptionTill"
                ),
              },
            };
          }
          return account;
        }),
      };
    case ERROR_UPDATE_ACCOUNT:
    case ERROR_GET_ACCOUNTS:
    case ERROR_DELETE_ACCOUNT:
    case ERROR_ACCOUNT_VERIFY:
      return {
        ...state,
        busy: false,
        message:
          action.payload || "Something happened wrong try again after sometime",
      };
    default:
      return state;
  }
};

export default accounts;

export function useAccounts() {
  return useSelector((state: Record<string, any>) => state.accounts);
}
