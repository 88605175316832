import { useMemo, useState } from "react";
import styles from "./LinksData.module.css";
import { get } from "lodash";
import {
  Button,
  Center,
  Flex,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Grid,
  Spacer,
} from "@chakra-ui/react";
import { MdArrowDropDown } from "react-icons/md";
import { useLinksMaster } from "../../../store/linkMaster/reducer";
import { ILinks } from "../../../types/links";
import LinksDetailComponents from "../LinksDetailComponents/LinksDetailComponents";
import { useLinkCategories } from "../../../store/linkCategory/reducer";
import { ILinksCategory } from "../../../types/linksCategory";
import { ListManager } from "react-beautiful-dnd-grid";
import LinksDragNDrop from "../linksDragNDrop/LinksDragNDrop";
import { useDispatch } from "react-redux";
import { REQUEST_UPDATE_LINK_CATEGORY_FOR_LINK_ORDER } from "../../../store/linkCategory/linkCategoryActionType";
export interface Props {
  searchQuery: string;
}

const LinksData: React.FC<Props> = ({ searchQuery: query }) => {
  const [filterValue, setFilterValue] = useState<ILinksCategory>();
  const [sortedList, setSortedList] = useState<any>();

  const { masterLinks } = useLinksMaster();
  const { linkCategory } = useLinkCategories();

  const dispatch = useDispatch();

  const sortList = () => {
    const newArray = sortedList
      .sort((a: any, b: any) => a.order - b.order)
      .map((list: any, index: number) => {
        let obj = { ...list, index: index };
        return obj;
      });

    setSortedList([...newArray]);
    const newArrayId = newArray.map((item: ILinks) => {
      return item._id;
    });
    const data = {
      payload: { links: newArrayId },
      _id: get(filterValue, "_id"),
    };
    //API should called here
    dispatch({ type: REQUEST_UPDATE_LINK_CATEGORY_FOR_LINK_ORDER, data });
  };

  const noop = function (sourceIndex: any, destinationIndex: any) {
    if (destinationIndex === sourceIndex) {
      return;
    }
    if (destinationIndex === 0) {
      sortedList[sourceIndex].order = sortedList[0].order - 1;
      sortList();
      return;
    }

    if (destinationIndex === sortedList.length - 1) {
      sortedList[sourceIndex].order =
        sortedList[sortedList.length - 1].order + 1;
      sortList();
      return;
    }
    if (destinationIndex < sourceIndex) {
      sortedList[sourceIndex].order =
        (sortedList[destinationIndex].order +
          sortedList[destinationIndex - 1].order) /
        2;

      // setSortedList

      sortList();
      return;
    }
    sortedList[sourceIndex].order =
      (sortedList[destinationIndex].order +
        sortedList[destinationIndex + 1].order) /
      2;

    sortList();
  };

  const searchColumns = useMemo(() => ["title"], []);

  const search = (link: Record<string, any>) => {
    return searchColumns.some((column) => {
      return (
        get(link, column, "")
          .toString()
          .toLowerCase()
          .indexOf(query.toLowerCase()) > -1
      );
    });
  };

  if (!masterLinks.length) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <>
        <div className={styles.filterSection}>
          <Menu>
            <MenuButton
              as={Button}
              variant="ghost"
              rightIcon={<MdArrowDropDown />}
              style={{
                border: "1px solid white",
                width: "80%",
                backgroundColor: "white",
                borderRadius: "20px",
                fontWeight: "bold",
              }}
            >
              <Flex>
                <Center>
                  <HStack>
                    <Image
                      ml={3}
                      src="/Images/User/3filterIcon.png"
                      alt="filter Icon"
                    />
                    <span>:</span>

                    <Text>
                      {!filterValue  ? "All" : filterValue?.title}
                    </Text>
                  </HStack>
                </Center>
              </Flex>
            </MenuButton>
            <MenuList width={"100%"}>
              <MenuItem 
                key={"All"}
                value={"All"}
                className={styles.selectOpt}
                onClick={() => {
                  setFilterValue(undefined);
                }}
              >
                {/* {!linkCategory.length ? "Loading..." : "All"} */}
              </MenuItem>

              {linkCategory.map((value: any) => {
                return (
                  <MenuItem
                    key={value.title}
                    value={value.title}
                    className={styles.selectOpt}
                    onClick={() => {
                      setFilterValue(value);
                      const temps = linkCategory.filter(
                        (item: ILinksCategory) =>
                          item._id.toString() === value._id
                      );

                      const temp_data = temps[0].links.map(
                        (link: ILinks, index: number) => {
                          let temp = { ...link, order: index };
                          return temp;
                        }
                      );
                      setSortedList(temp_data);
                    }}
                  >
                    {value.title}
                  </MenuItem>
                );
              })}
            </MenuList>
          </Menu>
        </div>

        {filterValue === undefined ? (
          <Grid
            templateColumns="repeat(3, 1fr)"
            gap={6}
            className={styles.mainAppDiv}
          >
            {masterLinks.filter(search).map((item: ILinks, index: number) => {
              return (
                <LinksDetailComponents
                  link={item}
                  index={index}
                  key={item._id}
                />
              );
            })}
          </Grid>
        ) : 
          <div className={styles.allAppsDiv}>
            <ListManager
              items={sortedList.filter(search) as ILinks[]}
              direction="horizontal"
              maxItems={3}
              render={(links: ILinks) => <LinksDragNDrop link={links} />}
              onDragEnd={noop}
            />
          </div>
        }
      </>
    </>
  );
};

export default LinksData;
