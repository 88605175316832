export const homeData = [
  {
    src: "/Images/Home/1useraccount.png",
    title: "Accounts",
    className: ["imageTitle"],
    link: "accounts",
  },
  {
    src: "/Images/Home/2users.png",
    title: "Users",
    className: ["imageTitle"],
    link: "users",
  },
  {
    src: "./Images/Home/3links.png",
    title: "Links",
    className: ["imageTitle"],
    link: "links",
  },
  {
    src: "/Images/Home/4message.png",
    title: "Messages",
    className: ["imageTitle"],
    link: "",
  },
  {
    src: "/Images/Home/5dataanyalistik.png",
    title: "Analystics",
    className: ["imageTitle"],
    link: "",
  },
  {
    src: "/Images/Home/6tagurls.png",
    title: "TAG URL'S",
    className: ["imageTitle"],
    link: "",
  },
  {
    src: "/Images/Home/7setting.png",
    title: "Placeholders",
    className: ["imageTitle"],
    link: "",
  },
  {
    src: "/Images/Home/7setting.png",
    title: "Graphics",
    className: ["imageTitle"],
    link: "",
  },
  {
    src: "/Images/Home/7setting.png",
    title: "Settings",
    className: ["imageTitle"],
    link: "",
  },
  {
    src: "/Images/Home/8admin.png",
    title: "Admins",
    className: ["imageTitle"],
    link: "",
  },
  {
    src: "/Images/Home/8admin.png",
    title: "QR Codes",
    className: ["imageTitle"],
    link: "",
  },
  {
    src: "/Images/Home/8admin.png",
    title: "Blocked Words",
    className: ["imageTitle"],
    link: "",
  },
  {
    src: "/Images/Home/8admin.png",
    title: "Tickit Center",
    className: ["imageTitle"],
    link: "",
  },
  {
    src: "/Images/Home/8admin.png",
    title: "It Support",
    className: ["imageTitle"],
    link: "",
  },
];
