import React, { useState } from "react";
import styles from "./UserTable.module.css";

interface Props {
  id: any;
  value: any;
  setActiveButton: any;
  active: any;
}

const UserBtn: React.FC<Props> = ({ ...props }) => {
  const clickHandler = () => {
    props.setActiveButton(props.id);
  };
  return (
    <>
      <button
        className={` ${styles.acTypeBtn} ${
          props.active ? styles.activeacTypeBtn : null
        } `}
        value={props.value}
        onClick={clickHandler}
      >
        {props.value}
      </button>
    </>
  );
};

export default UserBtn;
