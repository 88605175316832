import { get } from "lodash";
import { useSelector } from "react-redux";
import { IUser } from "../../types/user";
import {
  REQUEST_USERS,
  SET_USERS,
  REQUEST_DELETE_USER,
  SUCCESS_DELETE_USER,
  ERROR_DELETE_USER,
  REQUEST_ASSIGN_ROLE_USER,
  SUCCESS_ASSIGN_ROLE_USER,
  ERROR_ASSIGN_ROLE_USER,
  PRO_USER_ERROR,
  PRO_USER_REQUEST,
  PRO_USER_SUCCESS,
  REQUEST_RESETPOPUP,
  SUCCESS_RESETPOPUP,
  ERROR_RESETPOPUP,
} from "./userActionType";

const initialState = {
  busy: false,
  message: "",
  users: [],
};
const users = (state = initialState, action: Record<string, any>) => {
  switch (action.type) {
    case REQUEST_USERS:
    case REQUEST_DELETE_USER:
    case REQUEST_ASSIGN_ROLE_USER:
    case REQUEST_RESETPOPUP:
    case PRO_USER_REQUEST:
      return {
        ...state,
        busy: true,
        message: "",
      };
    case SET_USERS:
      return {
        ...state,
        busy: false,
        message: "",
        users: action.payload,
      };

    case SUCCESS_DELETE_USER:
      return {
        ...state,
        busy: false,
        message: "",
        users: state.users.filter(
          (user) => get(user, "_id") !== action.payload
        ),
      };
    case SUCCESS_ASSIGN_ROLE_USER:
      return {
        ...state,
        busy: false,
        message: "",
        users: state.users.map((user: IUser) => {
          if (get(user, "_id") === get(action.payload, "userId")) {
            return {
              ...user,
              userType: action.payload.userType,
            };
          }
          return user;
        }),
      };

      case SUCCESS_RESETPOPUP:
        
      return {
        ...state,
        busy: false,
        message: "",
        users: state.users.map((user: IUser) => {
          if (get(user, "_id") === get(action.payload, "_id")) {
            return {
              ...user,
              isPrivacyAccepted: {value: action.payload.payload.isPrivacyAccepted},
              isFirstVisit: {value: action.payload.payload.isFirstVisit},
            };
          }
          return user;
        }),
      };

    case PRO_USER_SUCCESS:
      return {
        ...state,
        busy: false,
        message: "",
        users: state.users.map((user) => {
          if (get(user, "_id") === get(action.payload, "userId")) {
            return {
              //@ts-ignore
              ...user,
              isPro: !get(user, "isPro"),
              subscriptionTill: get(action.payload, "payload.subscriptionTill"),
            };
          }
          return user;
        }),
      };

    case ERROR_DELETE_USER:
    case ERROR_ASSIGN_ROLE_USER:
      case ERROR_RESETPOPUP:
    case PRO_USER_ERROR:
      return {
        ...state,
        busy: false,
        message:
          action.payload || "Something happened wrong try again after sometime",
      };
    default:
      return state;
  }
};
export default users;

export function useUsers() {
  return useSelector((state: Record<string, any>) => state.users);
}
