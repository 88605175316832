import { BrowserRouter as Routers, Route, Routes } from "react-router-dom";
import { Fragment } from "react";
// Component Importing
import Home from "./pages/Home/Home";
import axios from "axios";
import { get } from "lodash";
import Login from "./pages/Login/Login";
import AuthenticatedRoute from "./Component/Auth/AuthenticatedRoute";
import AccountMaster from "./pages/AccountMaster/AccountMaster";
import AccountDetails from "./Component/AccountTable/AccountComponents/AccountDetails/AccountDetails";
import LinksMaster from "./pages/LinksMaster/LinksMaster";
import { LinksForm } from "./Component/linksDetails/LinksForm/LinksForm";
import CategoryMaster from "./pages/CategoryMaster/CategoryMaster";
import UserDetails from "./Component/UserTable/UserComponents/UserDetails/UserDetails";
import UserMaster from "./pages/UserMaster/UserMaster";

const App = () => {
  axios.interceptors.request.use(
    (config: any) => {
      config.headers.authorization = localStorage.getItem("token");
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (config) => {
      return config;
    },
    (error) => {
      if (get(error, "response.status") === 403) {
        localStorage.clear();
        window.location.href = "/login";
      }
      return Promise.reject(error);
    }
  );
  return (
    <>
      <div className="bgImage">
        <Routers>
          <Fragment>
            <Routes>
              <Route path="/login" element={<Login />}></Route>
              <Route path="/" element={<AuthenticatedRoute />}>
                <Route path="/" element={<Home />} />

                <Route path="accounts">
                  <Route path="" element={<AccountMaster />} />
                  <Route
                    path="account_details/:accountToken"
                    element={<AccountDetails />}
                  />
                </Route>
                <Route path="users">
                  <Route path="" element={<UserMaster />} />
                  <Route
                    path="user_details/:userToken"
                    element={<UserDetails />}
                  />
                </Route>

                <Route path="links">
                  <Route path="" element={<LinksMaster />} />
                  <Route path="add" element={<LinksForm />} />
                  <Route path="update/:id" element={<LinksForm />} />
                </Route>
                <Route path="category" element={<CategoryMaster />}></Route>

                <Route path="*" element={<Login />} />
              </Route>
              <Route path="/" element={<Home />} />
            </Routes>
          </Fragment>
        </Routers>
      </div>
    </>
  );
};

export default App;
