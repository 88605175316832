import {
  Flex,
  FormControl,
  Input,
  InputGroup,
  InputLeftAddon,
  Stack,
} from "@chakra-ui/react";
import styles from "./TextFormField.module.css";
import React from "react";
import { Field } from "formik";
export interface FieldType {
  required: boolean;
  name: string;
  fieldName: string;
  type: string;
  disabled?: boolean;
  error: string | undefined;
}

export const TextFormField: React.FC<FieldType> = (props) => {
  return (
    <>
      <FormControl isRequired={props.required}>
        <Stack spacing={1}>
          <InputGroup className={styles.inputGroup}>
            <InputLeftAddon
              children={props.name}
              className={styles.childTitle}
            />
            <Field
              name={props.fieldName}
              type={props.type}
              className={props.error ? styles.errorField : styles.field}
            >
              {/* @ts-ignore */}
              {({ field, meta: { touched } }) => {
                return (
                  <Input
                    focusBorderColor="none"
                    className={styles.inputField}
                    disabled={!!props.disabled}
                    type={props.type}
                    {...field}
                    errorBorderColor={"red"}
                  />
                );
              }}
            </Field>
          </InputGroup>
        </Stack>
      </FormControl>
    </>
  );
};
