import { useSelector } from "react-redux";
import { ISuspendType } from "../../types/suspendType";
import {
  ERROR_SUSPEND_TYPE,
  REQUEST_SUSPEND_TYPE,
  SET_SUSPEND_TYPE,
} from "./suspendTypeActionTypes";

interface IState {
  busy: boolean;
  message: string;
  suspendMessageType: ISuspendType[];
}

const initialState: IState = {
  busy: false,
  message: "",
  suspendMessageType: [],
};

const suspendMessageType = (
  state: IState = initialState,
  action: Record<string, any>
) => {
  switch (action.type) {
    case REQUEST_SUSPEND_TYPE:
      return {
        ...state,
        busy: true,
        message: "",
      };

    case SET_SUSPEND_TYPE: {
      return {
        ...state,
        busy: false,
        message: "",
        suspendMessageType: action.payload,
      };
    }
    case ERROR_SUSPEND_TYPE:
      return {
        ...state,
        busy: false,
        message:
          action.payload || "Something happened wrong try again after sometime",
      };
    default:
      return state;
  }
};
export default suspendMessageType;

export function useSuspendMessageType() {
  return useSelector((state: Record<string, any>) => state.suspendMessageType);
}
