import {
  Button,
  Center,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import styles from "./CategoryMaster.module.css";
import CategoryData from "../../Component/LinkCategory/CategoryData/CategoryData";
import { REQUEST_LINK_CATEGORY } from "../../store/linkCategory/linkCategoryActionType";
import { useLinkCategories } from "../../store/linkCategory/reducer";
import { Link } from "react-router-dom";
import { Image, useDisclosure } from "@chakra-ui/react";
import { AiOutlinePlus } from "react-icons/ai";
import AddLinksCategory from "../../Component/modules/LinkCategory/AddLinksCategory";

const CategoryMaster = () => {
  const dispatch = useDispatch();
  const [searchQuery, setSearch] = useState("");

  const {
    isOpen: isAddOpen,
    onOpen: onAddOpen,
    onClose: onAddClose,
  } = useDisclosure();
  const { linkCategory, busy: linkCategoryBusy } = useLinkCategories();

  if (!linkCategoryBusy && !(linkCategory || []).length) {
    dispatch({ type: REQUEST_LINK_CATEGORY });
  }

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <div className={styles.mainOuterDiv}>
        <div className={styles.MainInnerDiv}>
          {/* <Button onClick={onOpen}>Add Category</Button> */}
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Create Your Category</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <FormControl>
                  <FormLabel>Category Name</FormLabel>
                  <Input placeholder="category name" />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Category Image</FormLabel>
                  <Input placeholder="Category Image" />
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <Button colorScheme="blue" mr={3}>
                  Add
                </Button>
                {/* <Button onClick={onClose}>Cancel</Button> */}
              </ModalFooter>
            </ModalContent>
          </Modal>

          <div>
            <div className={styles.addLinkBtnDiv} onClick={onAddOpen}>
              <AiOutlinePlus className={styles.plusicon} />
              <button className={styles.addLinkBtn}>Add Category</button>
            </div>
          </div>
          <div className={styles.GgoBackDiv}>
            <Link to="/links">
              <p className={styles.goBackIcon}>Go Back</p>
            </Link>
          </div>

          <div className={styles.homeBtnDiv}>
            <Link to="/">
              <Image
                className={styles.homeIcon}
                src="/Images/homebutton.png"
                alt="home button"
              ></Image>
            </Link>
          </div>

          <div className={styles.MainDivCate}>
            <Heading
              size="md"
              fontWeight="semibold"
              m={"0px 20px"}
              className={styles.searchdiv}
            >
              <Center>
                <Input
                  id="search"
                  type="text"
                  placeholder="Search..."
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  style={{
                    border: "1px solid white",
                    width: "95%",
                    backgroundColor: "white",
                    borderRadius: "10px",
                  }}
                ></Input>
              </Center>
            </Heading>
            <CategoryData searchQuery={searchQuery} />
          </div>
        </div>
      </div>

      {isAddOpen && (
        <AddLinksCategory isOpen={isAddOpen} onClose={onAddClose} />
      )}
    </>
  );
};

export default CategoryMaster;
