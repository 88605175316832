import React, { useEffect, useState } from "react";
import styles from "../../../AccountTable/AccountComponents/AccountDetails/AccountDetails.module.css";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";

function InternatNotes() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = React.useRef();
  const finalRef = React.useRef();
  const [resize, setResize] = React.useState("horizontal");

  return (
    <>
      <Button className={` ${styles.notes} ${styles.golabalEditing} `} onClick={onOpen} px={3} py={"22px"}>Internal Notes</Button>

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        id="internalNotesMainDiv"
        
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create your notes</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              {/* <FormLabel>First name</FormLabel> */}
              <Textarea
                placeholder="Here is a enter notes"
                size="sm"
                resize={resize}
              />
              {/* <Input ref={initialRef} placeholder="" /> */}
            </FormControl>
          </ModalBody>

          <ModalFooter className={styles.lastDiv}>
            <Button colorScheme="blue" mr={3}>
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default InternatNotes;
