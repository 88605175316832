import { all, fork } from "redux-saga/effects";
import authSaga from "./auth/saga";
import accountSaga from "./account/saga";
import betaUserSaga from "./betaUser/saga";
import linkMasterSaga from "./linkMaster/saga";
import linkCategorySaga from "./linkCategory/saga";
import usersSaga from "./user/saga";
import feedbackSaga from "./feedback/saga";
import feedbackMessageTypeSaga from "./feedbackMessageType/saga";
import userActionSaga from "./userActions/saga";
import suspendTypeSaga from "./suspendType/saga";
export default function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(accountSaga),
    fork(betaUserSaga),
    fork(linkMasterSaga),
    fork(linkCategorySaga),
    fork(usersSaga),
    fork(feedbackSaga),
    fork(feedbackMessageTypeSaga),
    fork(userActionSaga),
    fork(suspendTypeSaga),
  ]);
}
