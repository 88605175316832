import React, { useEffect, useState } from "react";
import Button from './Button'
import styles from "./LinksForm.module.css"
interface Props {
  proValue: boolean
  onChangeData?: (data: any) => void
}

const Stddata: React.FC<Props> = ({ onChangeData, proValue }) => {
  const [activeButton, setActiveButton] = useState<number>(1)

  const button_Data = [
    {
      name: "pro",
      value: "Pro"
    },
    {
      name: "free",
      value: "Free"
    }
  ];

  useEffect(() => {
    if (proValue) {
      const data = button_Data.findIndex(element => element.name === "pro")
      setActiveButton(data)
    }
  }, [])

  return (
    <div className={styles.btnDivFor}>
      {button_Data.map((item, index) => (
        <div key={item.value} >
          <Button
            id={index}
            value={item.value}
            setActiveButton={(id: any) => {
              setActiveButton(id)
              onChangeData && onChangeData(button_Data[id])
            }}
            active={activeButton === index ? true : false}
          />

        </div>
      ))}
    </div>
  );
}

export default Stddata;
