import { get } from "lodash";
import { useSelector } from "react-redux";
import { ILinksCategory } from "../../types/linksCategory";
import {
  ERROR_CREATE_LINK_CATEGORY,
  ERROR_UPDATE_LINK_CATEGORY_FOR_LINK_ORDER,
  ERROR_UPDATE_LINK_CATEGORY_INDEX,
  REQUEST_CREATE_LINK_CATEGORY,
  REQUEST_LINK_CATEGORY,
  REQUEST_UPDATE_LINK_CATEGORY_FOR_LINK_ORDER,
  REQUEST_UPDATE_LINK_CATEGORY_INDEX,
  SET_LINK_CATEGORY,
  SET_UPDATE_LINK_CATEGORY_FOR_LINK_ORDER,
  SET_UPDATE_LINK_CATEGORY_INDEX,
  SUCCESS_CREATE_LINK_CATEGORY,
  REQUEST_UPDATE_LINK_CATEGORY,
  SET_UPDATE_LINK_CATEGORY,
  ERROR_UPDATE_LINK_CATEGORY,
} from "./linkCategoryActionType";

const initialState: {
  busy: boolean;
  message: string;
  linkCategory: ILinksCategory[];
} = {
  busy: false,
  message: "",
  linkCategory: [],
};

const linkCategories = (state = initialState, action: Record<string, any>) => {
  switch (action.type) {
    case REQUEST_LINK_CATEGORY:
    case REQUEST_UPDATE_LINK_CATEGORY_FOR_LINK_ORDER:
    case REQUEST_UPDATE_LINK_CATEGORY_INDEX:
    case REQUEST_CREATE_LINK_CATEGORY:
    case REQUEST_UPDATE_LINK_CATEGORY:
      return {
        ...state,
        busy: true,
        message: "",
      };

    case SET_LINK_CATEGORY:
      return {
        ...state,
        busy: false,
        message: "",
        linkCategory: action.payload,
      };

    case SET_UPDATE_LINK_CATEGORY_INDEX:
      return {
        ...state,
        busy: false,
        message: "",
        linkCategory: action.payload,
      };

    case SUCCESS_CREATE_LINK_CATEGORY:
      return {
        ...state,
        busy: false,
        message: "",
        linkCategory: [...state.linkCategory, action.payload],
      };

    case SET_UPDATE_LINK_CATEGORY:
      return {
        ...state,
        busy: false,
        message: "",
        linkCategory: state.linkCategory.map((item: ILinksCategory) => {
          return get(item, "_id") === action.payload._id ? action.payload : item;
        }),
      };

    case SET_UPDATE_LINK_CATEGORY_FOR_LINK_ORDER:
      return {
        ...state,
        busy: false,
        message: "",
        linkCategory: state.linkCategory.map((item: ILinksCategory) => {
          return get(item, "_id") === action.payload._id ? action.payload : item;
        }),
      };

    case ERROR_UPDATE_LINK_CATEGORY_FOR_LINK_ORDER:
    case ERROR_UPDATE_LINK_CATEGORY_INDEX:
    case ERROR_UPDATE_LINK_CATEGORY:
    case ERROR_CREATE_LINK_CATEGORY:
      return {
        ...state,
        busy: false,
        message:
          action.payload || "Something happened wrong try again after sometime",
      };

    default:
      return state;
  }
};

export default linkCategories;
export function useLinkCategories() {
  return useSelector((state: Record<string, any>) => state.linkCategories);
}
